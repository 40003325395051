.mylebc--banner {
  &.hummingbird {

    .banner--copy {

      h1 {
        font-size: 48px;
        line-height: 54px;
        font-family: $medium;
        margin: 0 0 25px 0;

        @media screen and (max-width: 500px) {
          font-size: 36px;
        }

      }

      h2 {
        font-family: $book;
        font-size: 27px;
        line-height: 35px;
        color: white;
        margin: 0 0 20px 0;
      }

      .app--store {
        margin: 25px 0 20px 0;

        a {
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 150px 47px;
          width: 150px;
          height: 47px;

          &.apple {
            background-image: url('../img/mylebc/apple--white.svg');
            margin-right: 10px;
          }

          &.google {
            background-image: url('../img/mylebc/google--white.svg');
          }

        }

      }

    }

  }
}

#slide1, #pfp1 {
  transform: translateY(115px);
}
#slide2, #pfp2 {
  transform: translateY(209px);
}
#slide3 {
  transform: translateY(385px);
  // padding-bottom: 700px;
}
#slide4 {
  transform: translateY(450px);
  padding-bottom: 700px;
}
#pfp3 {
  transform: translateY(385px);
  // transform: translateY(393px);
}
#slide1, #slide2, #slide3, #pfp1, #pfp2, #pfp3 {
  padding-bottom: 550px;
}
#pfp4 {
  transform: translateY(450px);
  padding-bottom: 700px;
}
// #pfp2 {
//   transform: translateY(209px);
//   padding-bottom: 500px;
//   opacity: 0;
// }

.mylebc--animation {
  padding: 60px 0;

  @media screen and (max-width: 750px) {
    padding: 40px 0;
  }

  &.desktop {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }

  &.mobile {
    display: none !important;

    .mylebc--animation-flex {
      align-items: center;

      @media screen and (max-width: 767px) {
        margin-bottom: 60px;

        &:nth-child(even) {
          flex-direction: row-reverse;

          .anim-container {
            img.frame {
              float: left;
            }

            .screen-container {
              img {
                left: 4px;
                right: auto;
              }
            }

          }

        }

        &:last-child { margin-bottom: 0 }

      }

      .copy {
        @media screen and (max-width: 550px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }

       .anim-container {

         @media screen and (max-width: 550px) {
           width: 100%;
         }

          img.frame {
            position: relative;
            float: right;
            width: 212px;

            @media screen and (max-width: 550px)  {
              float: left;
            }

          }

          .screen-container {

            img {
              top: 53px !important;
              right: 4px !important;

              @media screen and (max-width: 550px) {
                left: 4px !important;
                right: auto !important;
              }

            }

          }

       }

    }

    @media screen and (max-width: 767px) {
      display: block !important
    }

  }

  .mylebc--animation-flex {
    display: flex;
    flex-wrap: wrap;

    .copy {
      width: 40%;
      display: inline-block;

      @media screen and (max-width: 900px), (max-height: 810px) {
        width: 50%;
      }

      h2 {
        font-family: $medium;
        font-size: 27px;
        color: $dark-blue;
        line-height: 35px;
        margin: 0 0 20px 0;
      }

      p {
        font-family: $arial;
        font-size: 14px;
        line-height: 17px;
        color: $dark-blue;
      }

      .app--store {
        margin: 25px 0 0 0;

        a {
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 150px 47px;
          width: 150px;
          height: 47px;

          &.apple {
            background-image: url('../img/mylebc/apple.svg');
            margin-right: 10px;
          }

          &.google {
            background-image: url('../img/mylebc/google.svg');
          }

        }

      }

      .button {
        margin-top: 30px;

        a {
          font-family: $arial;
          font-size: 14px;
          color: $dark-blue;
          font-weight: bold;
          text-decoration: none;
          text-align: left;
          position: relative;

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/svg/icon--arrow-right-red.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 19px 19px;
            width: 19px;
            height: 19px;
            @include valign;
            position: absolute;
            margin-left: 8px;
          }

        }

        &.demo {
          a {
            display: inline-block;
            background-color: $blue;
            text-align: center;
            font-family: $arial;
            font-size: 14px;
            color: white;
            text-decoration: none;
            font-weight: $bold;
            padding: 15px 20px 15px 45px;
            position: relative;

            &:after {
              display: none;
            }

            &:before {
              display: inline-block;
              content: '';
              background-image: url('../img/svg/svg--icon-play.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 19px 19px;
              width: 19px;
              height: 19px;
              position: absolute;
              left: 15px;
              top: 13px;
            }
          }
        }

      }

    }

    .anim-container {
      width: 60%;
      position: relative;
      z-index: 100;

      @media screen and (max-width: 900px), (max-height: 810px) {
        width: 50%;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;

        &.frame {
          @media screen and (max-width: 900px), (max-height: 810px) {
            width: 380px;
            height: auto;
          }
          @media screen and (max-height: 810px) {
            transform: translateY(100px);
          }
          @media screen and (max-width: 768px) {
            transform: translateY(0) !important;
          }
        }

      }

      .demo {
        display: none;
        position: absolute;
        z-index: 99;
        width: 95px;
        right: 140px;
        top: 275px;
        height: 95px;

        @media screen and (max-width: 767px) {
          display: block !important;
          left: 60px;
          top: 180px;

          &.hidemob {
            display: none !important;
          }

        }

        a {
          display: block;
          background-image: url('../img/svg/svg--play-icon.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 95px 95px;
          width: 95px;
          height: 95px;
        }

      }

      .screen-container {
        position: relative;
        z-index: -99;

        @media screen and (max-height: 810px) {
          transform: translateY(100px);
        }
        @media screen and (max-width: 768px) {
          transform: translateY(0) !important;
        }

        img {
          position: absolute;
          top: 72px;
          right: 116px;

          @media screen and (max-width: 900px), (max-height: 810px) {
            top: 54px !important;
            right: 87px !important;
          }

          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            opacity: 0;
            top: 100px;
          }

          @media screen and (max-width: 900px), (max-height: 810px) {
            width: 205px;
            height: auto;
          }

        }

      }

    }

  }

}

.mylebc--strip {
  background-color: $blue;
  padding: 25px 0;
  text-align: center;

  p {
    font-family: $light;
    font-size: 24px;
    color: white;
    margin: 0;

    a {
      color: white;
      text-decoration: underline;
      font-family: $medium;
    }

  }

}

.mylebc--faq {
  padding: 60px 0;
  background-color: #ededed;

  &.hide {
    display: none !important;
  }

  @media screen and (max-width: 750px) {
    padding: 40px 0;
  }

  .faq--intro {
    text-align: center;
    margin-bottom: 40px;

    h2 {
      font-family: $medium;
      font-size: 27px;
      color: $dark-blue;
      margin: 0 0 20px 0;
    }

    p {
      font-family: $arial;
      font-size: 14px;
      color: $dark-blue;
      padding-bottom: 20px;
      width: 100%;
      max-width: 450px;
      margin: 0 auto;

      &:last-child { padding-bottom: 0 }

    }

  }

  .faq--accordions {

    .accordion {
      position: static !important;
      cursor: pointer;
      border-bottom: 1px solid #c2c2c2;

      .accord-title {
        position: relative;
        padding: 20px 50px 20px 65px;
        @include animate;
        width: calc(100% - 115px);
        max-width: 100%;
        margin: 0 auto;

        &.open {
          .plus--icon {
            transform: rotate(90deg);
          }
          .q--a {
            span {
              opacity: 1;
            }
          }
        }

        .q--a {
          display: inline-block;
          position: absolute;
          left: 22px;
          top: 18px;

          span {
            display: block;
            background-image: url('../img/mylebc/q.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            opacity: 0.30;
            @include animate;
          }

        }

        .plus--icon {
          display: block;
          position: absolute;
          right: 22px;
          top: 20px;
          @include animate;
          width: 9px;
          height: 16px;

          .bar {
            fill:none;
            stroke:#E6223D;
            stroke-width:1.4173;
            stroke-linecap:round;
            stroke-miterlimit:10;
            @include animate;
          }

        }

        h2 {
          font-family: $arial;
          font-size: 14px;
          color: $dark-blue;
          font-weight: 400;
          text-align: left !important;
          margin: 0;
        }

      }

      .accord-content {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        border-top: 0;
        @include animate;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;

        &.open {
          max-height: 10000px;
          opacity: 1;
          @include animate;
        }

        .accord-content-inner {
          padding: 20px 50px 20px 65px;
          position: relative;

          @media screen and (max-width: 750px) {
            padding: 0 20px 20px 65px;
          }

          .q--a {
            display: inline-block;
            position: absolute;
            left: 22px;
            top: 18px;

            @media screen and (max-width: 750px) {
              top: 4px;
            }

            span {
              display: block;
              background-image: url('../img/mylebc/a.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 20px 19px;
              width: 20px;
              height: 19px;
            }

          }

          h3 {
            margin: 0 0 20px 0;
          }

          ul {
            list-style: none;
            padding-left: 20px;

            li {
              padding-bottom: 10px;

              &:last-child {
                padding-bottom: 20px;
              }

              &:before {
                content: "\2022";
                color: $blue;
                font-weight: $regular;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
              }

            }

          }

          p, li {
            font-family: $arial;
            font-size: 14px;
            color: $dark-blue;
            font-weight: 400;
            text-align: left !important;
            margin: 0;
            padding-bottom: 30px;

            strong {
              font-weight: $bold;
            }

            &:last-child {
              padding-bottom: 0;
            }

          }

          a {
            text-decoration: underline;
            color: $blue;
          }

        }

      }

    }

  }

}

.mylebc--tools {
  background-color: $dark-blue;
  padding: 60px 0;

  @media screen and (max-width: 750px) {
    padding: 40px 0;
  }

  .title {
    text-align: center;
    margin-bottom: 60px;

    @media screen and (max-width: 750px) {
      margin-bottom: 40px;
    }


    h2 {
      font-family: $light;
      font-size: 27px;
      color: white;
      margin: 0;

      strong {
        font-family: $medium;
      }

    }

  }

  .mylebc--tools-flex {
    display: flex;
    flex-wrap: wrap;

    .col {
      display: flex;
      flex: 1 0 auto;
      width: calc(50% - 61px);
      text-decoration: none;
      color: white;

      @media screen and (max-width: 950px) {
        width: calc(50% - 31px);
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        min-height: 132px;
      }

      &:hover {
        .copy {
          p {
            &:after {
              background-image: url('../img/svg/icon--arrow-right-red.svg');
            }
          }
        }
      }

      &:first-child {
        padding-right: 60px;
        border-right: 1px solid white;

        @media screen and (max-width: 950px) {
          padding-right: 30px;
        }

        @media screen and (max-width: 767px)  {
          border-right: 0;
          border-bottom: 1px solid white;
          padding-bottom: 30px;
        }

      }

      &:nth-child(2) {
        padding-left: 60px;

        @media screen and (max-width: 950px) {
          padding-left: 30px;
        }

        @media screen and (max-width: 767px) {
          padding-top: 30px;
        }

      }

      .img {
        position: relative;
        min-width: 93px;
        height: 132px;

        @media screen and (max-width: 400px) {
          display: none;
        }

        .frame {
          display: block;
          position: absolute;
          z-index: 10;
          background-image: url('../img/mylebc/icon--frame.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 93px 132px;
          width: 93px;
          height: 132px;
        }

        img {
          position: absolute;
          z-index: 11;
          max-width: 46px;
          width: 100%;
          height: auto;
          @include center;
          top: 50%;
          transform: translateY(-50%);
        }

      }

      .copy {
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        -ms-flex: 0 1 auto;

        @media screen and (max-width: 400px) {
          padding-left: 0;
        }

        h3 {
          font-family: $medium;
          font-size: 25px;
          color: white;
          padding: 0 0 10px 0;
        }

        h4 {
          font-family: $book;
          font-size: 17px;
          color: white;
          margin: 0 0 10px 0;
          line-height: 19px;
        }

        p {
          font-family: $arial;
          font-size: 14px;
          color: white;
          margin: 0;

          &:after {
            display: block;
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            background-image: url('../img/svg/arrow--white.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            @include animate;
          }

        }

      }

    }

  }

}
