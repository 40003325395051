// selects all IE <= 9
.ie {
  header {
    .blue_nav {
      .strapline {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  header {
    .blue_nav {
      .strapline {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
  }
}


header {

  &.lity-hidden {
    display: none;
  }

    .blue_nav {
      width: 100%;
      background-color: #26344C;
      padding: 15px 0;

      @media screen and (max-width: 825px) {
        position: relative;
      }

      &.sticky {
        @media screen and (min-width: 810px) {
          position: fixed;
          top: 0px;
          z-index: 9999;

          .strapline {
            display: none;
          }
        }
      }

      @media screen and (max-width: 810px) {
          padding: 15px 0 0;
        }

      @include clearfix;
      .logo {
        background-image: url('../img/svg/logo--white.svg');
        background-size: 135px 66px;
        width: 135px;
        height: 66px;
        display: inline-block;
        background-repeat: no-repeat;

        transition: all .2s ease-in-out;

        @media screen and (max-width: 810px) {
          float: none;
          position: relative;
          z-index: 2;
        }

        &.sticky {
          @media screen and (min-width: 810px) {
            background-size: 75px 37px;
            width: 75px;
            height: 37px;
            background-repeat: no-repeat;
            float: left;
          }
        }
      }

      .topbar {
        text-align: right;
        margin-bottom: 12px;

        @media screen and (max-width: 810px) {
          margin-bottom: 0;
          text-align: center;
          position: absolute;
          @include center;
          left: 20%;
          top: 33px;
          z-index: 1;
        }

        @media screen and (max-width: 425px) {
          left: 33%;
        }

        .portal--link {
          background-color: $blue;
          color: white;
          text-decoration: none;
          padding: 5px 25px 5px 40px;
          font-weight: bold;
          font-size: 13px;
          @include animate;
          margin-right: 10px;
          background-image: url('../img/svg/icon--portal.svg');
          background-repeat: no-repeat;
          background-position: left 18px center;
          background-size: 14px 14px;

          @media screen and (max-width: 810px) {
          }

          &:hover {
            background-color: $red;
          }

        }

      }

      .strapline {
        font-family: $book;
        color: white;
        font-size: 20px;
        text-align: right;

        @media screen and (max-width: 810px) {
          display: none;
        }
      }

      .-right {
        margin-top: 7px;

        @media screen and (max-width: 810px) {
          float: none;
        }
      }

      a {
        display: inline-block;
        color: $dark-blue;
        text-decoration: none;
        font-family: $arial;
        font-size: 13px;
        vertical-align: middle;
        padding: 0 15px 0 0;

        &.twitter {
          background-image: url('../img/svg/social--twitter-red.svg');
          background-size: 25px 25px;
          width: 25px;
          height: 25px;
          padding: 0px;

          @media screen and (max-width: 810px) {
            display: none;
          }

        }

        &.linkedin {
          background-image: url('../img/svg/social--linkedin-red.svg');
          background-size: 25px 25px;
          width: 25px;
          height: 25px;
          padding: 0px;

          @media screen and (max-width: 810px) {
            display: none;
          }

        }
        &.home {
          background-image: url('../img/svg/icon--home.svg');
          background-size: 25px 25px;
          width: 25px;
          height: 25px;
          padding: 0px;
        }
      }

      @media screen and (max-width: 810px) {
        // display: none;
      }

      .search {
        display: inline-block;
        vertical-align: middle;

        @media screen and (max-width: 810px) {
          display: none;
        }

        .search_button {
          background-image: url('../img/svg/icon--search.svg');
          background-size: 12px 12px;
          background-repeat: no-repeat;
          background-position: center;
          background-color: $red;
          border: none;
          display: inline-block;
          width: 25px;
          height: 25px;
          vertical-align: middle;
          -webkit-border-radius:0 !important;
          border-radius:0 !important;
          margin-left: -4px;
        }

        .searchbar {
          display: inline-block;
          form {
            display: inline-block;
            .search_form {
              display: inline-block;

              input[type="search"] {
                font-family: $arial;
                font-size: 13px;
                // border: 1px solid $dark-blue;
                border: none;
                padding: 5px;
                -webkit-border-radius:0;
                display: inline-block;
                border-radius:0 !important;
                -webkit-appearance: none;
              }
              input[type="search"]:focus {
                outline: none !important;
              }

              input[type="submit"] {
                display: inline-block;
                padding: 4px;
                color: white;
                border: none;
                font-size: 13px;
                margin-left: -5px;
              }
            }
          }
        }
      }

      .burger {
          width: 23px;
          height: 23px;
          display: none;
          cursor: pointer;
          background-image: url('../img/svg/icon-burger.svg');
          background-repeat: no-repeat;
          background-size: contain;
          transition: background .3s ease-in-out;
          @include valign;
          position: absolute;
          right: 20px;
          z-index: 2;

          &.close {
              background-image: url('../img/svg/icon-burger-close.svg');
          }

          @media screen and (max-width: 825px) {
              display: block;
          }
      }

    }

}
