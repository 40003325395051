.home_banner {

  .container {
    @media screen and (max-width: 960px) {
      padding: 0;
    }
  }

  .new--grid {
    @include grid;

    .banner {
      display: inline-block;
      grid-column: 1 / span 2;
      background-color: $dark-blue;
      padding: 40px 60px;
      background-image: url('../img/home-bird.png');
      background-repeat: no-repeat;
      background-position: top 15px right 9px;
      background-size: 580px 545px;

      @media screen and (max-width: 1200px) {
        background-position: top -5px right -130px;
      }

      @media screen and (max-width: 960px) {
        padding: 30px;
        background-position: bottom -130px right -45px;
      }

      @media screen and (max-width: 850px) {
        background-position: top 15px right -100px;
        min-height: 45vw;
      }

      @media screen and (max-width: 700px) {
        background-position: top 15px right -200px;
      }

      @media screen and (max-width: 600px) {
        background-image: none;
        padding: 25px;
      }

      .valign {
        @include valign;
        width: 53%;

        @media screen and (max-width: 960px) {
          transform: translateY(0);
          top: 0;
          width: 100%;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
        }

      }

      h1 {
        font-family: $light;
        font-size: 71px;
        color: $red;
        margin-bottom: 32px;

        @media screen and (max-width: 960px) {
          br {
            display: none;
          }
        }

        @media screen and (max-width: 600px) {
          font-size: 12vw !important;
        }

        @media screen and (max-height: 810px) {
          font-size: 50px;

          // br {
          //   display: none;
          // }
        }

        @media screen and (max-width: 960px) {
          font-size: 60px;

          br {
            display: none;
          }
        }
      }

      p {
        font-family: $arial;
        font-size: 17px;
        color: white;
        // width: 100%;
        // max-width: 470px;
        margin: 0;

        @media screen and (max-height: 810px) {
          // max-width: 600px;
        }

        @media screen and (max-width: 960px) {
          // max-width: 100%;
        }

        a {
          color: white;
          text-decoration: underline;
        }

      }
    }

    .sidebar {
      display: inline-block;
      grid-column: 3 / span 1;
      background-color: #EDEDED;
      padding: 45px 45px;

      @media screen and (max-width: 1200px) {
        padding: 30px;
      }

      @media screen and (max-width: 850px) {
        grid-column: 1 / span 2;
      }

      @media screen and (max-width: 600px) {
        padding: 25px;
      }

      span {
        font-size: $arial;
        font-size: 15px;
        color: #9F9F9F;
        font-weight: normal;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-top: 20px;

        li {
          display: block;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(odd) {
            border-bottom: 1px solid #D5D5D5;
            padding-bottom: 30px;
          }

          .main--link {
            text-decoration: none;
            display: block;

            &:hover {
              h3 {
                &:after {
                  transform: translate(10px, -50%);
                }
              }
            }

            h3 {
              font-family: $arial;
              font-size: 29px;
              color: $dark-blue;
              font-weight: normal;
              margin: 0 0 15px 0;
              position: relative;
              display: inline-block;
              padding-right: 25px;

              &:after {
                display: inline-block;
                content: '';
                background-image: url('../img/svg/arrow--blue.svg');
                background-position: center;
                background-repeat: no-repeat;
                width: 11px;
                height: 17px;
                background-size: 11px 17px;
                @include valign;
                position: absolute;
                right: 0;
                @include animate;
              }

            }

          }

          p {
            font-family: $arial;
            font-size: 17px;
            color: $black;
            font-weight: normal;
            margin: 0;
            padding-bottom: 15px;

            &:last-child {
              padding-bottom: 0;
            }

            a {
              color: $dark-blue;
              text-decoration: none;
              font-weight: bold;
            }

          }

        }

      }

    }

  }

}

.client--routes {
  padding: 35px 0 10px 0;

  .new--grid {
    @include grid;

    .col {
      display: inline-block;
      text-decoration: none;
      color: $black;

      @media screen and (max-width: 600px) {
        grid-column: span 1 !important;
      }

      &:last-child {
        margin-right: 0;

        @media screen and (max-width: 850px) {
          grid-column: span 2;
        }

      }

      .image {
        display: block;
        width: 100%;
        // background-image: url('../img/blog-image.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 14vw;

        @media screen and (max-width: 850px) {
          height: 20vw;
        }

        @media screen and (max-width: 600px) {
          height: 46vw;
        }

      }

      .copy {
        padding: 45px;

        @media screen and (max-width: 1200px) {
          padding: 25px 25px;
        }

        h2 {
          display: inline-block;
          font-family: $arial;
          font-size: 29px;
          color: $black;
          margin: 0 0 20px 0;
          font-weight: normal;
          position: relative;
          padding-right: 25px;

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/svg/arrow--blue.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: 11px;
            height: 17px;
            background-size: 11px 17px;
            @include valign;
            position: absolute;
            right: 0;
            @include animate;
          }

        }

        p {
          font-family: $arial;
          font-size: 17px;
          color: $black;
          margin: 0;
        }

      }

      &:hover {
        .copy {
          h2 {
            &:after {
              transform: translate(10px, -50%);
            }
          }
        }
      }

    }

  }

}

.home--content {
  background-color: #EDEDED;
  padding: 40px 0;

  .new--grid {
    @include grid;

    .col {
      display: inline-block;
      background-color: white;
      text-decoration: none;

      a {
        text-decoration: none;
      }

      &.blog--item {
        position: relative;
        text-decoration: none;
        color: $black;
        @include animate;

        &:hover {
          background-color: #F7F7F7;
          .copy {
            background-color: #F7F7F7;
          }
        }

        @media screen and (max-width: 850px) {
          &:nth-child(4) {
            display: none;
          }
        }

        @media screen and (max-width: 600px) {
          grid-column: 1 / span 2;

          &:nth-child(4) {
            display: block;
          }

        }

        .image {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 14vw;

          @media screen and (max-width: 850px) {
            height: 20vw;
          }

          @media screen and (max-width: 600px) {
            height: 46vw;
          }

        }

        .copy {
          padding: 45px;
          @include animate;

          @media screen and (max-width: 1200px) {
            padding: 25px;
          }

          span {
            font-size: 15px;
            color: #9F9F9F;
            margin-bottom: 15px;
            display: none;

            &:nth-child(1) {
              display: block;
            }

          }

          h3 {
            font-family: $arial;
            font-size: 26px;
            color: $dark-blue;
            font-weight: normal;
            margin: 0 0 18px 0;
            line-height: 1.4;

            &:after {
              display: inline-block;
              content: '';
              background-image: url('../img/svg/arrow--blue.svg');
              width: 8px;
              height: 12px;
              background-size: 8px 12px;
              position: absolute;
              right: 35px;
              bottom: 35px;
            }

          }

          p {
            font-size: 17px;
            color: $black;
            margin: 0;
          }

        }

      }

      &.services {
        grid-column: 3 / span 1;
        grid-row: 1 / span 3;
        padding: 45px;

        @media screen and (max-width: 1200px) {
          padding: 25px 25px;
        }

        @media screen and (max-width: 850px) {
          grid-column: 2 / span 1;
        }

        @media screen and (max-width: 600px) {
          grid-column: 1 / span 2;
        }

        a {
          @include animate;
          &:hover {
            color: $red !important;
          }
        }

        h4 {
          font-family: $arial;
          font-size: 29px;
          color: $dark-blue;
          margin: 0 0 30px 0;
          font-weight: normal;
        }

        .main--link {
          font-family: $arial;
          font-size: 22px;
          color: $black;
          text-decoration: none;
          font-weight: normal;
          position: relative;
          display: inline-block;
          padding-right: 20px;
          margin: 0 0 20px 0;

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/svg/arrow--blue.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: 10px;
            height: 16px;
            background-size: 10px 16px;
            @include valign;
            position: absolute;
            right: 0;
            @include animate;
          }

        }

        ul {
          margin-bottom: 45px;

          li {
            padding-bottom:8px;

            a {
              font-family: $arial;
              font-size: 17px;
              color: $black;
              text-decoration: none;
              font-weight: normal;
            }

          }

        }

      }

      &.home--news-seeall {
        grid-column: 1 / span 2;
        text-align: right;
        text-decoration: none;
        display: block;
        position: relative;
        padding-right: 20px;
        background: none;

        span {
          display: block;
          font-family: $arial;
          font-weight: normal;
          font-size: 22px;
          color: $dark-blue;
          @include valign;
        }

        &:before {
          display: inline-block;
          content: '';
          width: calc(100% - 250px);
          height: 1px;
          background-color: #D5D5D5;
          @include valign;
          position: absolute;
          left: 0;
        }

        &:after {
          display: inline-block;
          content: '';
          background-image: url('../img/svg/arrow--blue.svg');
          width: 8px;
          height: 12px;
          background-size: 8px 12px;
          background-repeat: no-repeat;
          @include valign;
          position: absolute;
          right: 0;
        }

      }

    }

  }

}

.circle_links {
    padding: 70px 0;
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;

    @media screen and (max-width: 810px) {
        display: none;
    }

    @include clearfix;
    .stretch {
        width: 100%;
        display: inline-block;
        font-size: 0;
        line-height: 0
    }

.private {
    a {
        border-color: $green;
        color: $green;
        .hidden_info {
            background: rgba(118,178,56, 1);
            strong {
                border-color: #97D154;
            }
        }
        span {
            &.background {
                display: block;
                margin: 0 auto;
                margin-left: 62px;
            }
        }
    }
}

.corporate {
    a {
        border-color: $dark-green;
        color: $dark-green;
        .hidden_info {
            background: rgba(0,114,41, 1);
            strong {
                border-color: #54A06F;
            }
        }
        span {
            &.background {
                display: block;
                margin: 0 auto;
                margin-left: 40px;
            }
        }
    }
}

.professional {
    a {
        border-color: $blue;
        color: $blue;
        .hidden_info {
            background: rgba(0,172,201, 1);
            strong {
                border-color: #54C7DB;
            }
        }
        span {
            &.background {
                display: block;
                margin: 0 auto;
                margin-left: 30px;
            }
        }
    }
}

    .private, .corporate, .professional {
        width: 220px;
        display: inline-block;
        vertical-align: top;

        @media screen and (max-width: 750px) {
            width: 100%;
        }
        a {
            display: block;
            border-radius: 50%;
            border: 1px solid;
            width: 220px;
            height: 220px;
            font-family: $light;
            font-size: 27px;
            text-align: center;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: all 0.4s ease-in-out;

            @media screen and (max-width: 750px) {
                margin: 0 auto;
            }

            .hidden_info {
                position: absolute;
                width: inherit;
                height: inherit;
                border-radius: 50%;
                overflow: hidden;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                transform: scale(0);

                strong {
                    font-family: $book;
                    font-size: 20px;
                    color: white;
                    padding: 10px 0;
                    border-top: 1px solid;
                    border-bottom: 1px solid;
                }
                span {
                    &.icon {
                        display: block;
                        background-image: url('../img/svg/icon--right-arrow-white.svg');
                        background-size: 26px 26px;
                        width: 26px;
                        height: 26px;
                        margin: 0 auto;
                        margin-top: 30px;
                        background-repeat: no-repeat;
                    }

                    &.vertical {
                        display:block;
                        margin-top: 94px;
                    }
                }
            }

            span {
                &.background {
                    position: absolute;
                    top: 28%;
                    transition: all 0.4s ease-in-out;
                    transform: scale(1);
                    opacity: 1;
                }
            }
            strong {
                font-size: 19px;
                font-weight: normal;
            }
        }

        a:hover {
            box-shadow:
            inset 0 0 0 1px rgba(255,255,255,0.1),
            0 1px 2px rgba(0,0,0,0.1);

            span {
                &.background {
                    opacity: 0;
                    transition: all 0.4s ease-in-out;
                    transform: scale(0);
                }
            }

            .hidden_info {
                transform: scale(1);
                opacity: 1;
            }
        }

        h2 {
            text-align: center;
            font-family: $arial;
            font-size: 22px;
            color: $dark-blue;
            margin: 25px 0 15px 0;
        }
        p {
            font-family: $arial;
            font-size: 18px;
            color: black;
            text-align: center;
        }
    }

}

.home_tra {
    background-color: $dark-blue;

    @include clearfix;
    .tra, .jack {
        width: 50%;

        @media screen and (max-width:915px) {
            width: 100%;
            padding: 20px 0;
        }
    }

    .tra {
        .tra_container {
            padding: 0 105px 0 0;
            margin-left: 28%;

            @media screen and (max-width: 1100px) {
                margin-left: 10%;
            }

            @media screen and (max-width:915px) {
                margin-left: 0px;
                padding: 0 20px;
            }
            .logo {
                background-image: url('../img/svg/logo--tra-white.svg');
                display: block;
                height: 151px;
                max-width: 357px;
                width: 100%;
                margin-top: 70px;
                margin-bottom: 40px;
                background-repeat: no-repeat;
                background-size: contain;

                @media screen and (max-width: 915px) {
                    margin-top: 0px;
                }
                @media screen and (max-width:420px) {
                    padding-bottom: 38.8%;
                    height: 0;
                }
            }
            span {
                &.arrow {
                    background-image: url('../img/svg/icon--arrow-right-red.svg');
                    background-size: 26px 26px;
                    width: 26px;
                    height: 26px;
                    display: inline-block;
                    margin-left: 10px;
                    margin-top: -2px;
                    float: right;
                }
            }

            p {
                font-family: $arial;
                font-size: 18px;
                color: white;
            }
            a {
                display: inline-block;
                font-family: $medium;
                font-size: 17px;
                color: white;
                text-decoration: none;
                float: right;
                margin-top: 20px;
            }
        }
    }

    .jack {
        /* background-image: url('../img/jack.png'); */
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        height: 596px;

        @media screen and (max-width: 915px) {
            display: none;
        }

        .quote_box {
            display: block;
            background-color: rgba(44, 59, 84, 0.8);
            width: 215px;
            height: 343px;
            padding: 25px;
            margin: 70px 0 0 275px;

            @media screen and (max-width: 1100px) {
                margin-left: 165px;
            }

            p {
                font-family: $light;
                font-size: 23px;
                color: white;

                &:before {
                        content: '"';
                        color: $red;
                        font-weight: bolder;
                        font-family: $light;
                }

                &:after {
                        content: '"';
                        color: $red;
                        font-weight: bolder;
                        font-family: $light;
                }
            }
            strong {
                font-family: $arial;
                font-size: 18px;
                color: white;
                font-weight: normal;
            }
            span {

                &.ceo {
                    font-family: $arial;
                    color: $red;
                    font-size: 18px;
                }
            }
        }
    }
}

.home_video {
        width: 100%;
        margin: 0 auto;
        background-color: $dark-blue;
        position: relative;
        padding: 0;
        // padding: 60px 0;

        .afterglow {
          display: block;

          img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
          }
        }

        #myvideo {
          display: none;
        }

        video {
                width: calc(100% - 120px);
                margin: 0 auto;
                text-align: center;
                display: block;
                cursor: pointer;
                // background:transparent url('../img/about-banner.jpg') no-repeat;
                -webkit-background-size:cover !important;
                -moz-background-size:cover !important;
                -o-background-size:cover !important;
                background-size:cover !important;
        }

        .play {
            width: 150px;
            height: 150px;
            background-repeat: no-repeat;
            display: block;
            // background-image: url('../img/svg/svg--icon-play.svg');
            background-image: url('../img/svg/icon--yt-play.svg');
            background-size: 150px 150px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 767px) {
              width: 80px;
              height: 80px;
              background-size: 80px 80px;
            }

            &.hide {
                display: none;
            }
        }
}
