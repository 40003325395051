// selects all IE <= 9
.ie {
  .four_blocks {
    .block {
      .padding {
        h3 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
        a {
          font-family: $opensans !important;
          font-weight: $normal !important;
        }
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .four_blocks {
    .block {
      .padding {
        h3 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
        a {
          font-family: $opensans !important;
          font-weight: $normal !important;
        }
      }
    }
  }
}


.contact {
  padding: 0;
  @media screen and (max-width: 595px) {
    padding: 20px 0;
  }
  .opportunities {
    background-color: white;
    padding: 0;
    // max-width: 315px;
    max-width: 40px;
    margin-top: 150px;
    float: right;
    position: relative;
    z-index: -100;

    @media screen and (max-width: 690px) {
      display: none;
    }
    img {
      margin-top: 30px;
    }
  }

  .cta {
    margin-top: -120px !important;
    position: relative;
    z-index: 1000;

    @media screen and (max-width: 595px) {
      margin-top: 0px !important;
      padding-bottom: 1px;
    }

    a:first-of-type {
      padding-bottom: 0px;
    }

    h4 {
      &.press {
        margin-top: 20px;
        padding-left: 20px;
      }

      &.complaints {
        padding-left: 20px;
        font-size: 20px;
      }

    }

    a {
      span {
        &.complaints {
          margin-top: -5px;
        }
      }
    }

  }

  .text {
    form {
      input[type="text"] {
        display: inline-block;
        border: 1px solid #CCCCCC;
        width: 100%;
        height: 17px;
        max-width: 180px;
        padding: 8px;
        font-family: $arial;
        font-size: 14px;
        color: $dark-blue;
        float: left;
        outline: none;
      }
      button {
        background-color: $blue;
        background-image: url('../img/svg/icon--search.svg');
        background-size: 21px 21px;
        display: inline-block;
        width: 35px;
        height: 35px;
        border: none;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    a {
      float: right;
      span {
        float: right !important;
        margin-right: 0 !important;
        margin-left: 10px;
      }
    }
  }
}


.four_blocks {
  .container {
    @media screen and (max-width: 1060px) {
      padding: 0px;
    }
  }
    .block {
        display: inline-block;
        width: 49.7%;
        background-color: white;

        @media screen and (max-width: 775px) {
            width: 100%;
        }

        .padding {
            padding: 40px 40px 40px 50px;

            h3 {
                font-family: $light;
                font-size: 40px;
                color: $dark-blue;
                font-weight: normal;
                margin-bottom: 25px;

                @media screen and (max-width: 360px) {
                  font-size: 32px;
                }
            }

            p {
                font-family: $arial;
                font-size: 18px;
                color: $dark-blue;
            }

            a {
                display: block;
                text-decoration: none;
                color: $dark-blue;
                font-family: $medium;
                margin-top: 20px;

                &.phone {
                    font-size: 25px;

                    span {
                        background-image: url('../img/svg/icon--phone-green.svg');
                        background-size: 25px 25px;
                        display: block;
                        background-repeat: no-repeat;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin-right: 8px;
                        padding-bottom: 10px;
                    }
                }

                &.email {
                    font-size: 18px;

                    span {
                        background-image: url('../img/svg/icon--email.svg');
                        background-size: 25px 25px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin-right: 8px;
                        background-repeat: no-repeat;
                        padding-bottom: 10px
                    }
                }

                &.web {
                    font-size: 18px;
                    word-break: break-all;

                    span {
                        background-image: url('../img/svg/icon--web.svg');
                        background-size: 25px 25px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin-right: 8px;
                    }
                }

                &.btn {
                  display: inline-block;
                  padding: 12px 30px 12px 20px;
                  background-color: $dark-blue;
                  color: white;
                  background-image: url("../img/svg/arrow--white-small.svg");
                  background-size: 8px 12px;
                  background-position: center right 12px;
                  background-repeat: no-repeat;
                  @include animate;

                  &:hover {
                    background-color: $blue;
                  }

                }
            }
        }
    }

    .block:nth-child(1), .block:nth-child(4) {
        background-color: #F5F5F5;
    }
    &.team--block {
      background-color: $dark-blue;

      .block {
        background-color: $dark-blue !important;

        .padding {
          padding: 40px 0;

          h2, h3, h4, h5, p, li, a {
            color: white !important;
          }

          .btn {
            background-color: $red;
          }

        }

      }

    }
}


.branch_locator {
  @include clearfix;
}

#loc-list {
  .padding {
    padding: 30px;
  }
}

#map-container{
	clear: left;
	float: left;
	margin-top: 27px;
	height: 605px;
	width: 100%;

  @media screen and (max-width: 595px) {
    margin-top: 0px;
    height: auto;
  }
}

#map-container .custom-marker{
	width: 40px;
	height: 63px;
	color: #fff;
	background: url('../img/svg/icon--marker.svg') no-repeat;
    padding: 3px;
    cursor: pointer;
}

#loc-list{
	width: 330px;
	height: auto;
  position: absolute;
  right: 40px;
  margin-top: 40px;
  background-color: rgba(44, 59, 84, 0.9);
  overflow: auto;

  @media screen and (max-width: 595px) {
    position: static;
    width: 100%;
    background-color: $dark-blue;
    margin-top: 0px;
  }
}

#loc-list ul{
	display: block;
	clear: left;
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}

#loc-list .list-label{
	float: left;
	text-align: center;
  background-image: url('../img/svg/icon--marker.svg');
  background-size: 30px 53px;
  display: inline-block;
  width: 30px;
  height: 53px;
  padding-top: 10px;
  background-repeat: no-repeat;
	color: #fff;
	font-weight: bold;
}

#loc-list .list-details{
	float: right;
  display: inline-block;
  margin-left: 15px;
}

#loc-list .loc-dist{
	font-weight: bold;
	font-style: italic;
	color: #8e8e8e;
}

#loc-list li{
	display: block;
	clear: left;
	float: left;
	cursor: pointer;
  margin-bottom: 35px;
}

#loc-list li:last-child {
  margin-bottom: 30px;
}

#map-container .loc-name{
	color: white;
  font-family: $arial;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

#map-container .loc-addr {
	color: white;
  font-family: $arial;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 2px;
}

#map-container .loc-phone {
  font-family: $medium;
  font-size: 24px;
  color: white;
  margin: 12px 0;

  span {
    background-image: url('../img/svg/icon--phone-green.svg');
    background-size: 26px 26px;
    display: block;
    width: 26px;
    height: 26px;
    float: left;
    margin-right: 10px;
    margin-top: 0px;
  }
}

#map-container .loc-web a {
  font-family: $arial;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

#map-container .loc-link a {
  font-family: $arial;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  display: block;
  margin-top: 10px;
}


#loading-map{
	float: left;
	margin: 4px 0 0 10px;
	width: 16px;
	height: 16px;
	background: url(../images/ajax-loader.gif) no-repeat;
}

#map, .cmsmap{
	float: left;
	width: 100% !important;
	height: 605px !important;
}

.contact_map {
  @media screen and (max-width: 595px) {
    display: none;
  }
}

/* Infowindow Roboto font override */
.gm-style div, .gm-style span, .gm-style label, .gm-style a{
	font-family: $arial;
  font-size: 15px;
  color: $dark-blue;
}
.gm-style a {
  font-weight: bold;
  text-decoration: underline;
  padding-top: 10px;
}
.gm-style {
  .loc-name {
    color: $dark-blue !important;
  }
}

/* Modal window */

#overlay{
     position: fixed;
     left: 0px;
     top: 0px;
     width:100%;
     height:100%;
     z-index: 10000;
     background: url(../images/overlay-bg.png) repeat;
}

#modal-window{
	position: absolute;
	left: 50%;
    margin-left: -460px; /* width divided by 2 */
    margin-top: 60px;
    width: 920px;
	height: 590px;
	z-index: 10010;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 10px #656565;
}

#modal-content{
	float: left;
	padding: 0 22px; /* there's already a margin on the top of the map-container div */
}

#close-icon{
	position: absolute;
	top: -6px;
	right: -6px;
	width: 18px;
	height: 18px;
	cursor: pointer;
	background: #2c2c2c url(../images/close-icon.png) 3px 3px no-repeat;
	border: 1px solid #000;
	border-radius: 3px;
	box-shadow: 0 0 3px #656565;
}


/* The following is for the geocode page and not the store locator */

#geocode-result{
	clear: left;
	float: left;
	margin-top: 30px;
	width: 100%;
}

.callback {
  background-color: #F5F5F5;
  padding: 40px 0;
  position: relative;

  #callback {
    position: absolute;
    top: -110px;
  }

  .callback--intro {

    h2 {
      font-family: $light;
      font-size: 40px;
      color: $dark-blue;
      font-weight: normal;
      margin-bottom: 20px;
    }

    p {
      font-family: $arial;
      font-size: 18px;
      color: $dark-blue;
      font-weight: normal;
      margin-bottom: 20px;
    }

  }

  .callback--form {

    .pdf--download {
      padding-bottom: 30px;

      h3 {
        font-family: $arial;
        font-size: 30px;
        color: $dark-blue;
        font-weight: $bold;
        margin-bottom: 22px;
      }

      p {
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
        font-weight: normal;
      }

      .btn {
        font-family: $arial;
        font-weight: bold;
        font-size: 18px;
        display: inline-block;
        text-decoration: none;
        width: auto;
        text-align: center;
        padding: 12px 30px 12px 20px;
        background-color: $red;
        color: white;
        background-image: url("../img/svg/arrow--white-small.svg");
        background-size: 8px 12px;
        background-position: center right 12px;
        background-repeat: no-repeat;
        @include animate;
        margin: 10px 0;

        &:hover {
          background-color: $blue;
        }

      }

    }

    form {

      h4 {
        font-family: $bold;
        font-size: 20px;
        color: $dark-blue;
        margin: 0 0 25px 0;
      }

      label {
        display: block;
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
        font-weight: normal;
        margin-bottom: 8px;
      }

      input[type="radio"] {
        -webkit-appearance: radio;
      }

      input[type="checkbox"] {
        -webkit-appearance: checkbox;
        appearance: checkbox;
        margin: 0;
      }

      input[type="text"], input[type="email"], input[type="date"], input[type="tel"] {
        display: block;
        width: 100%;
        max-width: 300px;
        background-color: white;
        border: 1px solid #CCCCCC;
        padding: 8px 5px 8px 5px;
        outline: 0;
      }

      textarea {
        display: block;
        width: 100%;
        max-width: 300px;
        background-color: white;
        border: 1px solid #CCCCCC;
        padding: 8px 5px 8px 5px;
        resize: none;
        height: 85px;
        outline: 0;
      }

      .checkbox-custom  {
          opacity: 0;
          position: absolute;
          width: 25px;
          height: 25px;
      }
      .checkbox-custom, label  {
          display: inline-block;
          vertical-align: middle;
          margin: 0px;
          cursor: pointer;
      }
      .cc:after  {
          content: '';
          background: #fff;
          border: 1px solid #888888;
          display: inline-block;
          vertical-align: middle;
          width: 17px;
          height: 17px;
          padding: 2px;
          margin-left: 10px;
          text-align: center;
      }
      .checkbox-custom:checked + .cc:after {
          background: $red;
          box-shadow: inset 0px 0px 0px 4px #fff;
      }

      .callback--form-left, .callback--form-right {
        display: inline-block;
        max-width: 312px;
        width: 100%;
        vertical-align: top;

        @media screen and (max-width: 510px) {
          max-width: 100%;
        }

        &.-full {
          max-width: 664px;

          input, textarea {
            max-width: 100%;
            width: calc(100% - 10px)
          }

        }

      }

      .callback--form-left {
        margin-right: 40px;

        @media screen and (max-width: 510px) {
          margin-right: 0px;
        }

      }

      .callback--form-section {
        margin-bottom: 30px;
        display: flex;
        width: calc(100% - 300px);

        @media screen and (max-width: 595px) {
          width: 100%;
        }

        @media screen and (max-width: 510px) {
          display: block;
          margin-bottom: 0px;
        }

        &.checkbox-margin {
          @media screen and (max-width: 510px) {
            margin-bottom: 25px;
          }
        }

        select {
          display: block;
          background-color: white;
          width: 100%;
        }

        &.disclaimer {
          margin-bottom: 0;
          p {
            &:only-child, &:last-child {
              margin-bottom: 0;
            }
          }
        }

      }

      .custom--select {
        position: relative;

        @media screen and (max-width: 510px) {
          margin-bottom: 20px;
        }

        select {
          display: block;
          width: 100%;
          max-width: 312px;
          background-color: white;
          border: 1px solid #CCCCCC;
          padding: 8px 5px 8px 5px;

          margin: 0;
          outline:none; /* remove focus ring from Webkit */
          -webkit-appearance:none; /* remove the strong OSX influence from Webkit */
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;

          @media screen and (-webkit-min-device-pixel-ratio:0) {
            padding-right:30px;
          }

          @media screen and (max-width: 510px) {
            max-width: calc(100% + 2px) !important;
          }

        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          right: 10px;
          top: 42px;
          // bottom: 0;
          pointer-events:none;
          background-image: url('../img/svg/svg--icon-select-arrow.svg');
          background-size: 20px 10px;
          background-repeat: no-repeat;
          background-position: center center;
          width: 20px;
          height: 10px;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }

      }

      // .custom-radio {
      //   display: inline-block;
      //   position: relative;
      //   padding-left: 35px;
      //   margin-bottom: 12px;
      //   cursor: pointer;
      //   -webkit-user-select: none;
      //   -moz-user-select: none;
      //   -ms-user-select: none;
      //   user-select: none;
      //   margin-right: 60px;
      //   margin-top: 8px;
      //
      //   &.no-marg {
      //     margin-right: 0px !important;
      //   }
      //
      //   input {
      //     position: absolute;
      //     opacity: 0;
      //     cursor: pointer;
      //
      //     &:checked ~ .checkmark:after {
      //       display: block;
      //     }
      //
      //   }
      //
      //   .checkmark {
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     height: 20px;
      //     width: 20px;
      //     background-color: white;
      //     border-radius: 50%;
      //     border: 1px solid $dark-blue;
      //
      //     &:after {
      //       content: "";
      //       position: absolute;
      //       display: none;
      //       top: 3px;
      //       left: 3px;
      //       width: 14px;
      //       height: 14px;
      //       border-radius: 50%;
      //       background: $red;
      //     }
      //
      //   }
      //
      // }

      .form--hours {
        font-family: $arial;
        font-size: 12px;
        color: $dark-blue;
        font-weight: normal;
        margin-top: 10px;

        @media screen and (max-width: 510px) {
          margin-bottom: 20px;
        }

      }

      .callback--submit {
        display: block;
        background-image: url('../img/svg/svg--button-submit.svg');
        background-size: 131px 41px;
        background-repeat: no-repeat;
        background-position: center center;
        width: 131px;
        height: 41px;
        border: 0;
        background-color: #7AB929;
        -webkit-appearance: none;
        outline: 0;
      }

      @media screen and (max-width: 510px) {
        input, select, textarea {
          max-width: calc(100% - 10px) !important;
          margin-bottom: 20px;
        }
      }

      .callback--form-left, .callback--form-right {
        .--radio {
          .--space {
            display: inline-block;

            &:first-child {
              margin-right: 60px;
            }

            label {
              display: inline-block;
            }
            input {
              margin-right: 10px;
            }

          }
        }
      }

    }

    .callback--form-success {
      h3 {
        font-family: $medium;
        font-size: 30px;
        color: $dark-blue;
        font-weight: $bold;
        margin-bottom: 20px;
      }
      p {
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
        font-weight: normal;
      }
    }

  }

}

.four_blocks {
  &.no-grey {
    .block {
      background-color: white !important;
      width: 100%;

      .padding {
        padding: 40px 0px 40px 0px;
      }

    }
  }

  @media screen and (max-width: 1060px) {
    .container {
      padding: 0 20px;
    }
  }

}
