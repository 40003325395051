.mylebc--important {
  &.will {
    background-image: none;
    position: relative;
    overflow: hidden;
    min-height: auto;
  }
}

.mylebc--strip {
  &.powered {
    background-color: #aaaaaa;
    padding: 15px 0;

    @media screen and (max-width: 600px) {
      padding: 25px 0 15px 0;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }

      .copy {
        display: inline-block;
        margin-right: 15px;

        @media screen and (max-width: 600px) {
          display: block;
          width: 100%;
          margin: 0;
        }

        p {
          display: inline-block;
        }

      }

      .logo {
        display: inline-block;
        width: 100%;
        max-width: 240px;

        @media screen and (max-width: 600px) {
          display: block;
        }

        img {
          display: block;
          // width: 100%;
          // height: auto;
        }

      }

    }

  }
}
