.banner.about {
  background-image: url('../img/about-banner.jpg');
  .content {
    background-color: rgba(0, 168, 196, 0.8);

    h1 {
      margin-bottom: 25px;
    }
    p:last-child {
      margin-bottom: 35px;
    }
  }
}

.about {
  .text.-right {
    margin-top: 123px;

    @media screen and (max-width: 700px) {
      margin-top: 0px;
    }
  }
}
.home_tra {
  .private {
    background-image: url('../img/about-quote.jpg');
  }
}

#groupBoard {
  position: absolute;
  margin-top: -160px;

  @media screen and (max-width: 825px) {
    margin-top: 0;
  }

}

.about_people {
  background-color: #EEEEEE;
  padding: 40px 0;

  @media screen and (max-width: 595px) {
    text-align: center !important;
  }

  h2 {
    font-family: $light;
    color: $dark-blue;
    font-size: 53px;
    font-weight: normal;
    border-bottom: 1px solid $red;
    padding-bottom: 15px;
    margin-bottom: 35px;

    @media screen and (max-width: 595px) {
        margin-top: 30px;
    }
    @media screen and (max-width:450px) {
      font-size: 36px;
    }
  }

  .staff--list-flex {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;

    @media screen and (max-width: 500px) {
      display: block;
    }

    .inner {
      width: calc(100% / 3 - 20px);
      // background-color: pink;
      margin-right: 20px;

      @media screen and (max-width: 700px) {
        width: calc(100% / 2 - 20px);
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-right: 0 !important;
      }

    }

    .staff {
      margin-bottom: 40px;
      height: 415px;
      width: 100%;
      max-width: 255px;
      // background-color: blue;

      img {
        width: 100%;
        height: auto;
        max-width: 255px;
      }

      @media screen and (max-width: 893px) {
        margin-bottom: 30px;
      }

      @media screen and (max-width: 500px) {
        margin-right: 0;
      }

    }

  }
}
