// selects all IE <= 9
.ie {
  .blog_page {
    .colour_banner {
      h1 {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
  }
  .blog {
    .blog_list {
      .padding {
        h2 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
      }
      .twitter {
        .top_link {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
        span.center {
          .bottom_link {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
        }
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blog_page {
    .colour_banner {
      h1 {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
  }
  .blog {
    .blog_list {
      .padding {
        h2 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
      }
      .twitter {
        .top_link {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
        span.center {
          .bottom_link {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
        }
      }
    }
  }
}


.fix_clear {
    @include clearfix;
}

.colour_banner {
  &.blog_page {
    margin-bottom: 0;
    padding: 20px;
    background-color: rgba(0,0,0,0);

    .container {
      background-color: #F7F7F7;

      .filter-button-group, #form-ui {
        @include flex;
        padding: 25px 0 0 0;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 850px) {
          flex-wrap: wrap;
        }

        .title {
          display: inline-block;
          width: 100%;

          @media screen and (max-width: 1450px) {
            max-width: 400px;
          }

          @media screen and (max-width: 850px) {
            max-width: 100%;
            margin-bottom: 25px;
          }

          h1 {
            display: inline-block;
            color: $dark-blue;
            font-size: 32px;
            font-weight: normal;
            font-family: $arial;
            padding: 0;
            transform: translateY(-10px);
          }

        }

        .filters {
          display: inline-block;
          width: 100%;
          max-width: 800px;

          @media screen and (max-width: 1450px) {
            max-width: 1000px;
          }

          @media screen and (max-width: 850px) {
            max-width: 100%;
          }

          p {
            font-family: $arial;
            font-size: 17px;
            color: $dark-blue;
            font-weight: normal;
          }

          .checks {
            columns: 3;
            width: 100%;
            margin-top: 20px;

            @media screen and (max-width: 600px) {
              columns: 2;
            }

            @media screen and (max-width: 450px) {
              columns: 1;
            }

            label {
              font-family: $arial;
              font-size: 17px;
              color: $dark-blue;
              font-weight: normal;
              display: block;

              .custom-check {
                border-radius: 100%;
                background-color: white;
              }

              input {
                &:checked + .custom-check {

                  &:after {
                    display: block;
                    background-color: $red !important;
                    border-radius: 100%;
                  }

                }
              }

            }

          }

        }

      }

    }

  }
}

.blog_post {

  .colour_banner {
    @include clearfix;
      h1 {
          padding-right: 0 !important;
          float: left;
          width: 70%;
          display: inline-block;
      }

  }

  .container {
    max-width: 1060px;
  }

}

.blog--list {
  @include grid;
  margin-bottom: 40px;

  .blog--item {
    position: relative;
    text-decoration: none;
    color: $black;
    @include animate;
    background-color: white;
    border: 1px solid #D5D5D5;

    &:hover {
      background-color: #F7F7F7;
      .copy {
        background-color: #F7F7F7;
      }
    }

    @media screen and (max-width: 600px) {
      grid-column: 1 / span 2;

      &:nth-child(4) {
        display: block;
      }

    }

    a {
      text-decoration: none;
    }

    .image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 10vw;

      @media screen and (max-width: 1720px) {
        height: 14vw;
      }

      @media screen and (max-width: 850px) {
        height: 20vw;
      }

      @media screen and (max-width: 600px) {
        height: 46vw;
      }

    }

    .copy {
      padding: 30px;
      @include animate;

      @media screen and (max-width: 1200px) {
        padding: 25px;
      }

      span {
        font-size: 15px;
        color: #9F9F9F;
        margin-bottom: 15px;
        display: none;

        &:nth-child(1) {
          display: block;
        }

      }

      h3 {
        font-family: $arial;
        font-size: 26px;
        color: $dark-blue;
        font-weight: normal;
        margin: 0 0 18px 0;
        line-height: 1.4;

        &:after {
          display: inline-block;
          content: '';
          background-image: url('../img/svg/arrow--blue.svg');
          width: 8px;
          height: 12px;
          background-size: 8px 12px;
          position: absolute;
          right: 35px;
          bottom: 35px;
        }

      }

      p {
        font-size: 17px;
        color: $black;
        margin: 0;
      }

    }

  }

  .twitter {
    @extend .blog--item;
    background-color: white;
    border: 1px solid $blue;
    padding: 45px;
    display: block !important;

    @media screen and (max-width: 1200px) {
      padding: 25px;
    }

    &:hover {
      .copy {
        background-color: white;
      }
    }

    .twitter-article {
      &:nth-child(1) {
        border-bottom: 1px solid $blue;
        padding-bottom: 30px;
      }
      &:nth-child(2) {
        padding-top: 25px;
      }
    }

      span.center {
        display: block;
        margin: 0 auto;
        float: none;
        text-align: center;
        .bottom_link {
          font-family: $medium;
          font-size: 17px;
          color: $dark-blue;
          text-decoration: none;
          display: inline-block;
          font-weight: normal !important;
          margin-top: 12px;
          span {
            background-image: url('../img/svg/icon--arrow-right-red.svg');
            background-size: 26px 26px;
            background-repeat: no-repeat;
            display: inline-block;
            width: 26px;
            height: 26px;
            float: right;
            margin-left: 8px;
            margin-top: -3px;
          }
        }
      }
      img {
        width: 36px;
        height: 36px;
        float: left;
      }
      #tw2 {
        margin-top: 25px;
      }
      .tweetprofilelink {
        font-family: $arial;
        font-size: 16px;
        font-weight: bold;
        a {
          color: $dark-blue;
          text-decoration: none;
        }
        a.handle {
          margin-top: -2px;
          display: block;
        }
      }
      .twitter-pic {
        img {
          margin-right: 10px;
        }
      }
      .twitter-actions {
        display: none;
      }
      .twitter-text {
        position: relative;

        .tweet-time {
          a {
            text-decoration: none;
            font-family: $arial;
            font-size: 10px;
            color: $dark-blue;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

      }
      p {
        font-family: $arial;
        font-size: 14px;
        font-weight: normal;
      }

      span.copy {
        display: block;
        padding: 0;
        font-size: 17px;
        a {
          font-family: $arial;
          color: $dark-blue;
          font-weight: bold;
          text-decoration: underline;
        }
      }
  }

}

.blog_text {
    width: 100%;
    max-width: 647px;
    padding: 25px 0;
    float: left;

    a {
        text-decoration: none;
    }

    @include clearfix;

    @media screen and (max-width: 970px) {
        width: 65%;
    }
    @media screen and (max-width: 850px) {
        width: 60%;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    .blog_image {
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
        a {
            color: $dark-blue;
            text-decoration: none;
        }
        img {
            margin-top: 8px;
            width: 100%;
            max-width: 647px;
            height: auto;
        }
    }
    p {
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
        margin-bottom: 20px;
        a {
            color: $dark-blue;
            font-weight: bold;
            text-decoration: underline;
        }
    }
    ul {
        list-style: disc;
        padding-left: 20px;
        margin-bottom: 20px;
        li {
            padding: 10px 0;
            font-family: $arial;
            font-size: 18px;
            color: $dark-blue;
        }
    }
    ol {
        list-style: decimal;
        padding-left: 20px;
        margin-bottom: 20px;
        li {
            padding: 10px 0;
            font-family: $arial;
            font-size: 18px;
            color: $dark-blue;
        }
    }
    a {

        &.back_button {
            font-family: $medium;
            font-size: 17px;
            color: $dark-blue;
            text-decoration: none;
            display: block;
            margin: 45px 0 30px 0;

            span {
                background-image: url('../img/svg/icon--arrow-right-green.svg');
                background-size: 29px 29px;
                background-repeat: no-repeat;
                display: block;
                width: 29px;
                height: 29px;
                float: left;
                margin-right: 10px;
                margin-top: -5px;
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
                transform: rotate(180deg);
            }
        }

    }
}

.shares {
    margin: 30px 0;
    strong {
        float: left;
        vertical-align: middle;
        margin-right: 20px;
        margin-top: 6px;
    }
    a {
        display: inline-block;
        background-size: 34px 34px;
        width: 34px;
        height: 34px;

        &.linkedin {
            background-image: url('../img/svg/social--share-linkedin.svg');
        }

        &.twitter {
            background-image: url('../img/svg/social--share-twitter.svg');
            margin-left: 15px;
        }

        &.facebook {
            background-image: url('../img/svg/social--share-facebook.svg');
            margin-left: 15px;
        }

        &.google {
            background-image: url('../img/svg/social--share-google.svg');
            margin-left: 15px;
        }

    }
}

.grid  {
    margin: 0 auto;
    width: 1060px;
    display: block;
    margin-bottom: 30px;
    @include clearfix;

    @media screen and (max-width: 1059px) {
        width: 765px;
    }
    @media screen and (max-width: 765px) {
        width: 507px;
    }
    @media screen and (max-width: 540px) {
        width: 250px;
    }

    .stamp {
        left: 771px !important;
        position: absolute;

        @media screen and (max-width: 1059px) {
            left: 514px !important;
        }
        @media screen and (max-width: 765px) {
            left: 258px !important;
        }
        @media screen and (max-width: 540px) {
            left: 0px !important;
        }
    }
}


.other_articles {
    float: right;
    @include clearfix;
    width: 266px;

    @media screen and (max-width: 767px) {
        width: 100%;
        float: none;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .guide_pod {
        display: inline-block;
        background-color: #F5F5F5;
        float: right;
        width: 100%;
        max-width: 266px;
        // margin-top: 30px;
        position: relative;
        vertical-align: middle;
        margin-bottom: 25px;
        margin-top: 54px;

        @media screen and (max-width: 767px) {
            float: none;
            margin: 0 auto;
            margin-top: 0;
            margin-bottom: 0;
        }

        // .heading {
        //   @include clearfix;
        //     p {
        //         font-family: $book;
        //         font-size: 23px;
        //         color: $dark-blue;
        //         font-weight: normal;
        //         padding: 20px 91px 5px 20px;
        //         float: left;
        //
        //         strong {
        //             font-family: $medium;
        //         }
        //     }
        //     img {
        //         position: absolute;
        //         right: 15px;
        //         top: 10px;
        //     }
        // }

        h4 {
          font-family: $book;
          font-size: 23px;
          color: $dark-blue;
          font-weight: normal;
          padding: 20px 91px 5px 20px;
        }

        img {
          float: right;
          padding: 10px 15px 10px 10px;
          width: 76px;
          height: 107px;
        }

        p {
            font-family: $arial;
            font-size: 16px;
            color: $dark-blue;
            padding: 22px 20px 8px 20px;
            margin-bottom: 0;
        }

        a {
          &.report--dl {
            text-decoration: none;
            font-family: $medium;
            font-weight: $bold;
            font-size: 20px;
            color: $dark-blue;
            display: block;
            padding: 10px 20px 20px 20px;

            span {
              display: block;
              background-image: url('../img/svg/icon--arrow-right-dark-blue.svg');
              background-size: 26px 26px;
              background-repeat: no-repeat;
              width: 26px;
              height: 26px;
              float: right;
            }

          }
        }
    }

    .recent_articles {
        .article {
            margin-bottom: 30px;
            a {
                text-decoration: none;
                .title {
                    font-family: $light;
                    color: $dark-blue;
                    font-size: 28px;
                    margin-bottom: 12px;
                }
                .author {
                    font-family: $arial;
                    color: $dark-blue;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }

    .line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $dark-blue;
        margin-bottom: 30px;
    }

    .all_cat {
        margin-bottom: 30px;
        h5 {
            font-family: $arial;
            font-size: 21px;
            font-weight: bold;
            color: $dark-blue;
            margin-bottom: 15px;
        }
        ul {
            li {
                margin-bottom: 12px;
                a {
                    font-family: $arial;
                    font-size: 18px;
                    color: $dark-blue;
                    text-decoration: none;
                }
            }
        }
    }

    .current_cat {
        font-family: $arial;
        font-size: 21px;
        color: $dark-blue;
        margin-top: 40px;
        margin-bottom: 25px;

        ul {
            li {
                display: inline;

                &:after {
                    content: ',';
                    text-decoration: none;
                }

                &:nth-last-child(2):after {
                    content: '';
                }

                &.last {
                    span {
                        font-weight: normal;
                        text-decoration: none;
                    }

                    &:before {
                        content: 'and ';
                    }

                    &:after {
                        content: '';
                    }
                }

                a {
                    text-decoration: underline;
                    font-weight: bold;
                    color: $dark-blue;
                }

                &.no-comma {
                    &:after {
                        content: '';
                    }
                }
            }
        }
    }
}

#form-ui {

    button {
        border: none;
        background: none;
        text-align: left;
    }
    button:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .button {
        display: block;
        position: relative;
        cursor: pointer;
        margin-bottom: 25px;
        float: left;
        border: none;
        background: none;
        outline: none;
        font-family: $arial;
        font-size: 18px;
        color: white;
        text-align: left;
        margin-left: 20px;

        @media screen and (max-width: 720px) {
            float: none;
            display: inline-block;
        }
        @media screen and (max-width: 660px) {
            width: auto !important;
            margin-right: 35px;
        }
    }

label {
  font-family: $arial;
  font-size: 18px;
  color: white;
  text-align: left;
  margin-right: 20px;
  margin-bottom: 25px;
  display: inline-block;
  position: relative;
  padding-left: 28px;

  input {
          position: absolute;
          opacity: 0;
          cursor: pointer;

          &:checked + .custom-check {

            &:after {
              display: block;
              background-color: $red !important;
            }

          }

        }

  .custom-check {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          left: 0 !important;
          height: 18px;
          width: 18px;
          background-color: #eee;
          border: 1px solid #888888;

          @media screen and (max-width: 400px) {
            left: 23%;
          }

          &:after {
            content: ' ';
            position: absolute;
            display: none;
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
          }

        }

}

    .checks {
        @include clearfix;

        width: 575px;
        margin-top: 30px;
        display: block;

        @media screen and (max-width: 860px) {
            width: 440px;
            margin-top: 5px;
        }

        @media screen and (max-width: 720px) {
            width: 100%;
            float: none;
            margin-top: 30px;
        }
    }
}

.show_more{
    display: none;
    background:$dark-blue;
    margin: 20px 0;
    a{
        font: 18px $light;
        padding:10px 0;
        text-align: center;
        color:#FFF;
        text-decoration: none;
        display: block;
    }
}

.to_top {
    background-image: url('../img/svg/icon--to-top.svg');
    background-size: 62px 62px;
    display: block;
    width: 62px;
    height: 62px;
    position: fixed;
    right: 20px;
    bottom: 21px;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;

    &.hide {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
    }
}

.all {
    outline: none;
}
