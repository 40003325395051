.main_content {

  &.mydetails {

    .mydetails--intro {
      margin-bottom: 40px;

      p {
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
      }
    }

    .mydetails--form {

      .mydetails--row {
        display: block;

        .mydetails--input {
          display: inline-block;
          margin-right: 40px;

          @media screen and (max-width: 480px) {
            display: block;
            margin-right: 5px;
            margin-bottom: 20px;
          }

          label {
            font-family: $arial;
            font-size: 14px;
            font-weight: bold;
            color: $dark-blue;
            display: block;
            margin-bottom: 10px;
          }

          input {
            display: block;
            width: 210px;
            // max-width: 300px !important;
            color: $dark-blue;
            border: 1px solid #CCCCCC;
            padding: 5px;

            @media screen and (max-width: 568px) {
              width: 100%;
            }
          }
        }

        &.--bottom {
          margin-top: 30px;

          @media screen and (max-width: 480px) {
            margin-top: 0px;
          }
        }
      }

      .mydetails--submit {
        margin-top: 20px;

        input {
          -webkit-appearance: none;
          border: none !important;
          background-color: $green;

          &.mydetails--submit-button {
            display: block;
            background-image: url('../img/svg/svg--button-submit.svg');
            background-size: 104px 34px;
            width: 104px;
            height: 34px;
            background-repeat: no-repeat;
          }
        }

      }

      .mydetails--opt {
        display: block;

        .mydetails--opt-copy {
          font-family: $arial;
          font-size: 18px;
          color: $dark-blue;
          margin: 25px 0;
        }

        .mydetails--opt-form {
          display: block;

          .mydetails--opt-text {
            font-family: $arial;
            font-size: 18px;
            color: $dark-blue;
            font-weight: bold;
            display: inline-block;
            padding-bottom: 20px;
          }

          .mydetails--opt-check {
            display: inline-block;
            margin: 0 10px;
            padding-bottom: 20px;

            label {
              padding-right: 5px;
            }

            label + .checkbox-custom:before  {
                content: '';
                background: #fff;
                border: 1px solid #888888;
                display: inline-block;
                vertical-align: middle;
                width: 17px;
                height: 17px;
                padding: 2px;
                margin-right: 10px;
                text-align: center;
            }
            label + .checkbox-custom:checked:before {
                background: $red;
                box-shadow: inset 0px 0px 0px 4px #fff;
            }
          }

        }
      }

    }

  }

}
