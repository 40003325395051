.staff {
  h1 {
    width: 675px;

    @media screen and (max-width: 680px) {
      width: 100%;
    }
  }
}
.branches {
  .widget_container {
    float: right;
    width: 305px;

    @media screen and (max-width: 680px) {
      width: 100%;
      float: none;
      margin: 0 auto;
    }
    .big_staff {
      display: block;
      float: right;
      margin-top: -195px;

      @media screen and (max-width: 1060px) {
        float: none;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 25px
      }
    }

    .info {
      display: block;
      background-color: $dark-blue;
      width: 100%;
      max-width: 305px;
      float: right;
      margin-top: 30px;

      @media screen and (max-width: 680px) {
        float: none;
        margin: 0 auto;
        margin-bottom: 25px;
      }
      .padding {
        padding: 30px 25px 30px 25px;
        a {
          text-decoration: none;
          color: white;
          font-family: $medium;
          display: block;

          &.mobile {
            font-size: 25px;
            margin-bottom: 25px;
            span {
              background-image: url('../img/svg/icon--mobile.svg');
              background-size: 25px 25px;
              display: block;
              width: 25px;
              height: 25px;
              float: left;
              margin-right: 10px;
            }
          }

          &.tel {
            font-size: 25px;
            margin-bottom: 25px;
            span {
              background-image: url('../img/svg/icon--phone-green.svg');
              background-size: 25px 25px;
              display: block;
              width: 25px;
              height: 25px;
              float: left;
              margin-right: 10px;
            }
            strong {
              font-size: 14px;
              font-weight: normal !important;
              margin-left: 37px;
            }
          }

          &.email {
            font-size: 13px;
            margin-bottom: 36px;
            span {
              background-image: url('../img/svg/icon--email.svg');
              background-size: 25px 25px;
              display: block;
              width: 25px;
              height: 25px;
              float: left;
              margin-right: 10px;
              margin-top: -4px;
            }
          }

          &.linkedin {
            font-size: 15px;
            margin-bottom: 85px;
            span {
              background-image: url('../img/svg/social--linkedin-white.svg');
              background-size: 25px 25px;
              display: block;
              width: 25px;
              height: 25px;
              float: left;
              margin-right: 10px;
              margin-top: -4px;
            }
          }
        }
        .logo {
          background-image: url('../img/svg/logo--white.svg');
          background-size: 135px 66px;
          display: block;
          width: 135px;
          height: 66px;
          margin-bottom: 20px;
        }
        p {
          font-family: $arial;
          color: white;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.7;
          margin-bottom: 0px;
        }
      }
    }
    .adviser--btn {
      display: block;
      float: left;
      margin-top: 30px;
      background-color: $blue;
      text-align: center;
      text-decoration: none;
      font-family: $arial;
      font-size: 18px;
      padding: 15px 40px 15px 25px;
      color: white;
      width: calc(100% - 65px);
      font-weight: $bold;
      background-image: url('../img/svg/arrow--white-small.svg');
      background-repeat: no-repeat;
      background-position: center right 15px;
      background-size: 10px 15px;
      @include animate;

      &:hover {
        background-color: $red;
      }

      @media screen and (max-width: 680px) {
        float: none;
        max-width: 240px;
        margin: 0 auto 25px auto;
      }

    }
  }

  .text {

    @media screen and (max-width: 680px) {
      width: 100% !important;
      float: none;
    }
    h3 {
      font-family: $light;
      font-size: 34px;
      font-weight: normal;
      color: $dark-blue;
      margin-bottom: 15px;
      margin-top: 30px;
    }
    h3:first-child {
      margin-top: 0px;
    }
    p {
      font-family: $arial;
      font-size: 18px;
      color: $dark-blue;
    }
    ul {
      list-style: disc;
      padding-left: 20px;
      li {
        padding: 8px 0;
        color: $dark-blue;
        font-family: $arial;
        font-size: 18px;
      }
    }
  }
}
