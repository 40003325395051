// selects all IE <= 9
.ie {
  footer {
    .uptodate {
      h2 {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
    .grey_footer {
      .-left {
        h3 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
        .contact {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  footer {
    .uptodate {
      h2 {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
    .grey_footer {
      .-left {
        h3 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
        .contact {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
      }
    }
  }
}


footer {

  .uptodate {
    background-color: $dark-blue;
    width: 100%;
    padding: 20px 0;
    text-align: center;

    @include clearfix;

    h2 {
      font-family: $arial;
      font-size: 28px;
      color: white;
      display: inline-block;
      margin-left: -186px;

      @media screen and (max-width: 755px)  {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;

      }
    }

    .footer_social {
      display: inline-flex;
      display: -webkit-inline-flex;
      margin-left: 20px;
      position: absolute;
      align-items: center;

      @media screen and (max-width: 755px) {
        display: block;
        position: relative;
        margin: 0;
      }
      a {
        display: inline-block;
        &.twitter {
          display: inline-block;
          background-image: url('../img/svg/social--tw-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 31px 25px;
          width: 31px;
          height: 30px;
        }

        &.linkedin {
          display: inline-block;
          background-image: url('../img/svg/social--li-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 28px 26px;
          width: 28px;
          height: 30px;
          margin-left: 12px;
        }

        &.news {
          display: inline-block;
          background-image: url('../img/svg/icon--news-new.svg');
          background-size: 35px 30px;
          width: 38px;
          height: 30px;
          background-repeat: no-repeat;
          margin-left: 10px;
        }
      }
    }
  }

  .home--news {
    padding: 40px 0;
    background-color: #F7F7F7;

    @media screen and (max-width: 660px) {
      padding: 40px 0;
    }

    .flex {
      @include flex;

      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }

      .blog--item {
        display: inline-block;
        width: calc(100% / 3 - 24px);
        background-color: white;
        position: relative;
        @include animate;

        @media screen and (max-width: 950px) {
          width: calc(100% / 3 - 13px);
        }

        @media screen and (max-width: 800px) {
          width: calc(50% - 13px);

          &:first-child {
            width: 100%;
            margin-bottom: 25px;

            .image {
              min-height: 250px;
            }

          }

        }

        @media screen and (max-width: 550px) {
          width: 100% !important;
        }

        &:nth-child(2) {
          margin: 0 35px;

          @media screen and (max-width: 950px) {
            margin: 0 25px;
          }

          @media screen and (max-width: 800px) {
            margin: 0 25px 0 0;
          }

          @media screen and (max-width: 550px) {
            margin: 0 0 25px 0;
          }

        }

        &:hover {
          background-color: #F7F7F7;
        }

        a {
          color: $black;
          text-decoration: none;
        }

        .image {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          min-height: 250px;

          @media screen and (max-width: 950px) {
            min-height: 180px;
          }

          @media screen and (max-width: 550px) {
            min-height: 250px;
          }

        }

        .copy {
          padding: 35px 60px 35px 35px;

          @media screen and (max-width: 1100px) {
            padding: 25px 50px 25px 25px;
          }

          span {
            font-size: 15px;
            color: #9F9F9F;
            display: none;

            &:nth-child(1) {
              display: block;
            }

          }

          h3 {
            font-family: $arial;
            font-size: 26px;
            color: $dark-blue;
            font-weight: normal;
            margin: 15px 0 18px 0;
            line-height: 1.4;

            &:after {
              display: inline-block;
              content: '';
              background-image: url('../img/svg/arrow--blue.svg');
              width: 8px;
              height: 12px;
              background-size: 8px 12px;
              position: absolute;
              right: 35px;
              bottom: 35px;
            }

          }

          p {
            font-size: 17px;
            color: $black;
            margin: 0;
          }

        }

      }

    }

    .home--news-seeall {
      text-align: right;
      font-family: $arial;
      font-weight: normal;
      font-size: 22px;
      color: $dark-blue;
      text-decoration: none;
      display: block;
      margin: 40px 35px 0 0;
      position: relative;
      padding-right: 20px;

      &:before {
        display: inline-block;
        content: '';
        width: calc(100% - 250px);
        height: 1px;
        background-color: #D5D5D5;
        @include valign;
        position: absolute;
        left: 0;
      }

      &:after {
        display: inline-block;
        content: '';
        background-image: url('../img/svg/arrow--blue.svg');
        width: 8px;
        height: 12px;
        background-size: 8px 12px;
        background-repeat: no-repeat;
        @include valign;
        position: absolute;
        right: 0;
      }

    }

  }

  .grey_footer {
    background-color: #EEEEEE;
    padding: 40px 0;
    @include clearfix;

    .form {
      @media screen and (max-width: 870px) {
        width: 100% !important;
      }
    }
      .-left, .-right {
        width: 50%;

        @media screen and (max-width:870px) {
          float: none;
        }

        @media screen and (max-width:460px) {
          width: 100%;

        }

        h3 {
          font-family: $light;
          font-size: 40px;
          color: $dark-blue;
          margin-bottom: 15px;
          font-weight: normal;
          width: 405px;

          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }
        p {
          font-family: $arial;
          font-weight: bold;
          font-size: 15px;
          color: #878787;
        }
        .contact {
          font-family: $light;
          font-size: 33px;
          color: $dark-blue;
          text-decoration: none;

          span {
            background-image: url('../img/svg/icon--arrow-right-blue.svg');
            background-size: 26px 26px;
            width: 26px;
            height: 26px;
            display: inline-block;
            position: absolute;
            margin-top: 8px;
            margin-left: 15px;
          }
        }

        form {
          margin-bottom: 15px;
          .checkbox-custom  {
              opacity: 0;
              position: absolute;
              width: 25px;
              height: 25px;
          }
          .checkbox-custom, label  {
              display: inline-block;
              vertical-align: middle;
              margin: 0px;
              cursor: pointer;
          }
          label {
            font-family: $arial;
            font-size: 15px;
            color: $grey;
            font-weight: bold;
            vertical-align: middle;
          }
          .checkbox-custom + label:before  {
              content: '';
              background: #fff;
              border: 1px solid #888888;
              display: inline-block;
              vertical-align: middle;
              width: 17px;
              height: 17px;
              padding: 2px;
              margin-right: 10px;
              text-align: center;
          }
          .checkbox-custom:checked + label:before {
              background: $red;
              box-shadow: inset 0px 0px 0px 4px #fff;
          }

          input[type="email"] {
            display: inline-block;
            width: 100%;
            max-width: 260px;
            background-color: white;
            border: none;
            padding: 10px;
            margin: 15px 0 15px 0;
            font-family: $arial;
            font-size: 15px;
            color: $dark-blue;
            height: 17px;
            outline: none;
          }
          input[type="submit"] {
            display: inline-block;
            background-color: $blue;
            padding: 5px 15px 5px 15px;
            border: none;
            font-family: $arial;
            font-size: 15px;
            color: white;
            height: 37px;
            outline: none;
          }
        }
      }

      .footer_links {
        width: 50%;

        @media screen and (max-width: 1025px) {
          width: 47%;
        }

        @media screen and (max-width:870px) {
          width: 100%;
          display: inline-block;
          float: left;
          margin-top: 25px;

          .-left, .-right {
            display: inline-block;
          }
        }

        @media screen and (max-width:480px) {
          width: 100%;
          display: inline-block;
          float: none;
        }

        .-right {
          @media screen and (max-width: 870px) {
            float: right !important;
          }
          @media screen and (max-width:460px) {
            float: none;
          }
        }

        a.footer-section-link {
          font-family: $arial;
          font-size: 16px;
          color: $dark-blue;
          font-weight: bold;
          margin-bottom: 10px;
          text-decoration: none;
          display: block;

          &.single {
            margin-top: 30px;
          }

          &.mobile {
            @media screen and (max-width: 460px) {
              margin-top: 40px;
            }
          }
        }

        ul {
          li {
            padding: 2px 0;
            a {
              font-family: $arial;
              font-size: 14px;
              color: $grey;
              font-weight: bold;
              text-decoration: none;
            }
          }
        }

      }
  }

  .end {
    padding: 40px 0;
    text-align: right;
    @include clearfix;

    @media screen and (max-width: 750) {
      text-align: left;
    }

    .logos {
      float: left;
      width: 20%;
      min-width: 140px;
      max-width: 140px;

      @media screen and (max-width: 750px) {
        float: none;
        width: 100%;
        max-width: 100%;
        margin-bottom: 30px;
      }

      .logo {
        background-image: url('../img/svg/logo--black.svg');
        background-size: 140px 68px;
        width: 140px;
        height: 68px;
        display: block;
        margin: 0 auto;
      }

      .cyber-essentials {
        width: 80px;
        margin-top: 20px;
        margin: 20px auto 0 auto;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

      }

    }

    .-right {
      width: calc(80% - 30px);
      padding-left: 30px;
      float: right;

      @media screen and (max-width: 750px) {
        width: 100%;
        float: none;
        padding: 0;
        text-align: left;
      }
      ul {
        margin-bottom: 20px;
        li {
          display: inline-block;
          a {
            font-family: $arial;
            font-size: 13px;
            color: $dark-blue;
            text-decoration: none;
          }

          &.resp_spacing {
            @media screen and (max-width: 575px) {
              display: block;
              height: 15px;
            }
          }

          &.slash {
            margin: 0 10px;
          }
        }
        li:last-child {
          a {
            color: $blue;
          }
        }
      }
      p {
        font-family: $arial;
        font-size: 11px;
        color: $dark-blue;
      }
    }
  }
}
