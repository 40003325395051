.main_content {

  &.core {

    .text {

      &.green {
        width: 50% !important;

        @media screen and (max-width: 700px) {
          width: 100% !important;
          float: none !important;
        }

        h2 {
          color: $green !important;
          border-bottom: 1px solid $green !important;
        }

        p {
          strong {
            color: $green !important;
          }
        }
      }

      &.blue {
        margin-top: 40px;
        width: 50% !important;

        @media screen and (max-width: 700px) {
          width: 100% !important;
        }

        h2 {
          color: $blue !important;
          border-bottom: 1px solid $blue !important;
        }

        p {
          strong {
            color: $blue !important;
          }
        }
      }

    }

    .links {
      margin-top: 50px !important;

      @media screen and (max-width: 700px) {
        margin-top: 20px !important;
        margin-bottom: 30px;
      }
    }

  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */

	.core_principles {
    width: 100% !important;
    height: auto !important;
		max-width: 418px !important;
		max-height: 418px !important;

		@media screen and (max-width: 700px) {
			width: 100% !important;
			height: auto;
		}
	}

}
