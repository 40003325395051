@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin bp($point) {
    @media screen and (min-width: $point) {
    	@content;
    }
}

@mixin animate {
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}

@mixin valign {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin center {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

@mixin flex {
	display: flex;
	display: -webkit-flex;
}

@mixin grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 35px;

	@media screen and (max-width: 850px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}

}
