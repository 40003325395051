// selects all IE <= 9
.ie {
  .careers_quotes {
    .quote {
      .text {
        p {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .careers_quotes {
    .quote {
      .text {
        p {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
      }
    }
  }
}


.colour_banner.dark_blue {
    background-color: $dark-blue;
}

.job-details {
    h2 {
        color: $red;
        font-size: 40px;
        font-family: "Gotham Light";
    }
}

.main_content.careers {
    padding: 65px 0 0 0;

    .cta {
        background-color: $red;
        margin-top: -185px;

        @media screen and (max-width: 595px) {
            margin-top: -40px;
            margin-bottom: 30px;
        }

        a {
            font-size: 18px;
            margin-top: 20px;

            span {
                background-image: url('../img/svg/icon--email.svg');
                background-size: 25px 25px;
                display: block;
                width: 25px;
                height: 25px;
                float: left;
                margin-top: -2px;
                margin-right: 8px;
            }
        }
    }

    .text.-right {
        width: 40%;
        margin-top: 165px;

        @media screen and (max-width:595px) {
            width: 100%;
            margin-top: 30px;
        }

        ul {
            list-style: disc;
            margin-left: 20px;

            li {
                font-family: $arial;
                font-size: 18px;
                color: $dark-blue;
                margin-bottom: 10px;
            }
        }
    }

    .text.-left {
        a {
            float: right;

            &:after {
                width: 26px;
                height: 26px;
                content: '';
                float: right;
                margin-right: 0px;
                margin-left: 10px;
                background-image: url('../img/svg/icon--home-arrow.svg');
            }
        }
    }
}

.careers_quotes {
    @include clearfix;

    background-color: $dark-blue;
    padding: 40px 0;

    .quote {
        @include clearfix;

        width: 50%;
        float: left;
        padding: 20px;
        box-sizing: border-box;

        @media screen and (max-width: 940px) {
            width: 100%;
            margin-bottom: 20px;
        }

        .text {
            width: 60%;
            float: left;

            p {
                font-family: $light;
                font-size: 23px;
                color: white;
                font-weight: normal;

                &:before,
                &:after {
                    font-weight: bold;
                    color: $red;
                    font-size: 25px;
                }

                &:before {
                    content: '“';
                }

                &:after {
                    content: '”';
                }
            }

            @media screen and (max-width: 940px) {
                width: 68%;
                padding-right: 20px;
            }

            @media screen and (max-width: 767px) {
                width: 55%;
            }

            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }

        .image {
            width: 147px;
            float: right;

            p {
                font-family: $arial;
                font-size: 18px;
                color: white;
                margin-bottom: 0;
                margin-top: 20px;
            }

            h4 {
                font-family: $arial;
                font-size: 18px;
                color: $red;
                font-weight: normal;
            }

            @media screen and (max-width: 568px) {
                float: none;
                margin: 0 auto;
                text-align: center;

                img {
                    margin-top: 20px;
                }

                h4 {
                    margin: 5px auto 0;
                }
            }
        }
    }
}

.jobs {
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    display: -webkit-flex;

    .job {
    width: 50%;
    // float: left;

    &:nth-child(1),&:nth-child(4),&:nth-child(5),&:nth-child(8),&:nth-child(9),&:nth-child(12),&:nth-child(13) {
      background-color: #F5F5F5;
    }

    @media screen and (max-width: 775px) {
      width: 100%;
      display: block;

      &:nth-child(even) {
        background-color: #F5F5F5 !important;
      }

      &:nth-child(odd) {
        background-color: #FFFFFF !important;
      }

    }

    .padding {
        padding: 50px;
        @include clearfix;
        h2 {
        font-family: $light;
        font-size: 40px;
        color: $dark-blue;
        font-weight: normal;
        }
        h3 {
        font-family: $light;
        font-size: 27px;
        color: $red;
        font-weight: normal;
        margin-bottom: 25px;
        }
        p {
        font-family: $arial;
        font-size: 18px;
        color: $dark-blue;
        }
        a {
        font-family: $medium;
        font-size: 17px;
        color: $dark-blue;
        text-decoration: none;
        float: right;
        margin-top: 10px;


        span {
            background-image: url('../img/svg/icon--arrow-right-green.svg');
            background-size: 26px 26px;
            display: block;
            width: 26px;
            height: 26px;
            float: right;
            margin-left: 10px;
            margin-top: -2px;
        }
        }
    }
    }
    // .job:nth-child(even) {
    // background-color: #F5F5F5;
    // }

    // @media screen and (max-width: 775px) {
    // .job:nth-child(3n+1) {
    //     background-color: #fff;
    // }
    //
    // .job:nth-child(2n+1) {
    //     background-color: #F5F5F5;
    // }
    // }
}
