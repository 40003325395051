@font-face {
    font-family: "Gotham Book";
    src: url("../fonts/gotham-book.eot"); /* IE9 */
    src: url("../fonts/gotham-book.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/gotham-book.woff") format("woff"), /* chrome, firefox */
    url("../fonts/gotham-book.woff2") format("woff2"),
    url("../fonts/gotham-book.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
    url("../fonts/gotham-book.svg#Gotham book") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Gotham Light";
    src: url("../fonts/gotham-light.eot"); /* IE9 */
    src: url("../fonts/gotham-light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/gotham-light.woff") format("woff"), /* chrome, firefox */
    url("../fonts/gotham-light.woff2") format("woff2"),
    url("../fonts/gotham-light.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
    url("../fonts/gotham-light.svg#Gotham light") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Gotham Medium";
    src: url("../fonts/gotham-medium.eot"); /* IE9 */
    src: url("../fonts/gotham-medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/gotham-medium.woff") format("woff"), /* chrome, firefox */
    url("../fonts/gotham-medium.woff2") format("woff2"),
    url("../fonts/gotham-medium.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
    url("../fonts/gotham-medium.svg#Gotham medium") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}
