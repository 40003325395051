.meet--team {
  background-color: white;

  .team--filter {
    @include flex;
    width: 100%;
    margin-top: 25px;

    @media screen and (max-width: 950px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 595px) {
      margin-top: 0;
    }

    #filter {
      display: inline-block;
      background-color: white;
      border: 1px solid $grey;
      padding: 8px 10px;
      font-family: $arial;
      font-size: 15px;
      color: $dark-blue;
      background-image: url('../img/svg/icon--search-box.svg');
      background-size: 33px 33px;
      background-repeat: no-repeat;
      background-position: center right;
      margin-right: 25px;
      width: 100%;
      max-width: 250px;
      margin-bottom: 25px;

      @media screen and (max-width: 550px) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }

    }

    .custom--select {
      position: relative;
      margin-right: 25px;
      margin-bottom: 25px;

      @media screen and (max-width: 550px) {
        width: 100%;
        margin-right: 0;
      }

      select {
        display: inline-block;
        width: 100%;
        max-width: 312px;
        background-color: white;
        border: 1px solid #CCCCCC;
        padding: 8px 5px 8px 5px;
        position: relative;

        margin: 0;
        outline:none; /* remove focus ring from Webkit */
        -webkit-appearance:none; /* remove the strong OSX influence from Webkit */
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        @media screen and (-webkit-min-device-pixel-ratio:0) {
          padding-right:30px;
        }

        @media screen and (max-width: 550px) {
          width: 100% !important;
          max-width: calc(100% + 2px) !important;
        }

      }

      &:after {
        display: block;
        content: '';
        @include valign;
        position: absolute;
        right: 10px;
        // bottom: 0;
        pointer-events:none;
        background-image: url('../img/svg/svg--icon-select-arrow.svg');
        background-size: 20px 10px;
        background-repeat: no-repeat;
        background-position: center center;
        width: 20px;
        height: 10px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }

    }

  }

  .team--list {
    width: 100%;
    display: flex !important;

    .inner {
      width: calc(100% / 5 - 16px);

      @media screen and (max-width: 1100px) {
        width: calc(100% / 4 - 16px);

        &:nth-child(4n + 4) {
          margin-right: 0 !important;
        }

      }

      @media screen and (max-width: 800px) {
        width: calc(100% / 3 - 16px);
        margin-right: 20px !important;

        &:nth-child(4n + 4) {
          margin-right: 20px !important;
        }

        &:nth-child(3n + 3) {
          margin-right: 0 !important;
        }

      }

      @media screen and (max-width: 600px) {
        width: calc(100% / 2 - 10px);
        margin-right: 20px !important;

        &:nth-child(4n + 4) {
          margin-right: 20px !important;
        }

        &:nth-child(3n + 3) {
          margin-right: 20px !important;
        }

        &:nth-child(even) {
          margin-right: 0 !important;
        }

      }

      @media screen and (max-width: 450px) {
        width: 100% !important;
        margin-right: 0 !important;

        &:nth-child(3n + 3), &:nth-child(4n + 4), &:nth-child(5n + 5), &:nth-child(even) {
          margin-right: 0 !important;
        }

      }

      &:nth-child(5n + 5) {
        margin-right: 0;

        @media screen and (max-width: 1100px) {
          margin-right: 20px;
        }

      }

      .staff {
        margin-right: 0;
        height: auto;
        padding-bottom: 70px;

        .tel, .email {
          display: block;
          font-family: $arial;
          font-size: 16px;
          font-weight: bold;
          color: $dark-blue;
          text-decoration: none;
          position: relative;
          padding-left: 33px;
          margin-top: 18px;

          &:before {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            background-size: 25px 25px;
            width: 25px;
            height: 25px;
            @include valign;
            position: absolute;
            left: 0;
          }

        }

        .email {
          text-decoration: underline;

          &:before {
            background-image: url("../img/svg/icon--email.svg");
          }

        }

        .tel {

          &:before {
            background-image: url("../img/svg/icon--phone-green.svg");
          }

        }

      }

    }

  }

}
