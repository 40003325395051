/* Fonts */

$book: 'Gotham Book', Arial, sans-serif;
$light: 'Gotham Light', Arial, sans-serif;
$medium: 'Gotham Medium', Arial, sans-serif;

$arial: Arial, sans-serif;

$opensans: 'Lato', Arial, sans-serif;

$lighte: 300;
$regular: 400;
$semibold: 600;
$bold: 700;
$normal: normal;

/* Colours */

$red: #E5243D;
$blue: #00ACC9;
$green: #64BB00;
$dark-green: #007229;
$dark-blue: #2C3B54;
$grey: #878787;
$black: #333333;
