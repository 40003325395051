// selects all IE <= 9
.ie {
  .job-details {
    h2 {
      font-family: $opensans !important;
      font-weight: $lighte !important;
    }
  }
  .job {
    .padding {
      h2 {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
    .widget_container {
      .find_more {
        .padding {
          h3 {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
          a.email {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
        }
      }
      .apply {
        .padding {
          h3 {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
        }
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .job-details {
    h2 {
      font-family: $opensans !important;
      font-weight: $lighte !important;
    }
  }
  .job {
    .padding {
      h2 {
        font-family: $opensans !important;
        font-weight: $lighte !important;
      }
    }
    .widget_container {
      .find_more {
        .padding {
          h3 {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
          a.email {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
        }
      }
      .apply {
        .padding {
          h3 {
            font-family: $opensans !important;
            font-weight: $lighte !important;
          }
        }
      }
    }
  }
}


.job {
    .text {
        @media screen and (max-width: 735px) {
            width: 100% !important;
        }
        h3 {
            font-family: $light;
            font-size: 34px;
            color: $dark-blue;
            font-weight: normal;
            margin-bottom: 15px;
            margin-top: 30px;
        }
        ul {
            list-style: disc;
            padding-left: 20px;
            li {
                margin-bottom: 10px;
            }
        }
    }

    .widget_container {
        width: 340px;
        float: right;

        @media screen and (max-width: 735px) {
            float: none;
            margin: 0 auto;
        }
        @media screen and (max-width:380px) {
            width: 100%;
        }
        .apply {
            background-color: #B82843;
            max-width: 340px;
            margin-top: -125px;

            @media screen and (max-width: 735px) {
                margin-top: 0px;
            }
            .padding {
                padding: 30px;
                h3 {
                    font-family: $light;
                    font-size: 37px;
                    color: white;
                    font-weight: normal;
                    margin-bottom: 15px;
                }
                p {
                    font-family: $arial;
                    font-size: 18px;
                    color: white;
                    margin-bottom: 22px;
                }
                form {
                    label {
                        font-family: $arial;
                        font-size: 18px;
                        color: white;
                        font-weight: bold;
                    }
                    input[type="text"], input[type="email"], input[type="tel"] {
                        width: 100%;
                        max-width: 261px;
                        background-color: white;
                        border: 1px solid #cccccc;
                        padding: 8px;
                        margin-top: 7px;
                        font-size: 14px;
                        color: #000000;
                        outline: none;
                    }
                    input[type="submit"] {
                        display: block;
                        padding: 15px;
                        width: 172px;
                        height: 51px;
                        background-color: $dark-blue;
                        border: 1px solid white;
                        font-family: $arial;
                        font-size: 18px;
                        color: white;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 30px;
                        font-weight: bold;
                        outline: none;
                    }

                    .files {
                        margin-top: 20px;
                        padding-top: 20px;
                        border-top: #26344C solid 2px;

                        p {
                            font-size: 12px;
                        }
                    }

                    label.file-upload input[type="file"] {
                            position: fixed;
                            top: -1000px;
                    }

                    .file-upload {
                        width: 100px;
                        display: block;
                        padding: 10px 0;
                        background-color: $dark-blue;
                        border: 1px solid white;
                        font-family: $arial;
                        font-size: 14px;
                        color: white;
                        text-align: center;
                        margin: 8px auto;
                        font-weight: normal;
                        float: right;
                        cursor: pointer;
                    }

                    /* .file-upload :invalid + span {
                            color: #A44;
                    }

                    .file-upload :valid + span {
                            color: #4A4;
                    } */

                    .file-name {
                        color: #000;
                        font-size: 13px;
                        margin-top: 9px;
                        float: left;
                        width: 170px;
                        background-color: #fff;
                        padding: 11px 10px;
                        box-sizing: border-box;
                    }

                    .file-container {
                        @include clearfix;
                    }
                }
            }
        }

        .find_more {
            margin-top: 30px;
            max-width: 340px;

            @media screen and (max-width: 735px) {
                margin-bottom: 30px;
            }
            .padding {
                padding: 30px;
                h3 {
                    font-family: $light;
                    font-size: 33px;
                    color: white;
                    font-weight: normal;
                    margin-bottom: 12px;
                }
                p {
                    font-family: $arial;
                    font-size: 16px;
                    color: white;
                    margin-bottom: 20px;
                }
                .phone {
                        font-size: 26px;
                        margin-bottom: 15px;
                        span {
                            background-image: url('../img/svg/icon--phone-green.svg');
                            background-size: 25px 25px;
                            display: block;
                            width: 25px;
                            height: 25px;
                            float: left;
                            margin-left: 0px;
                            margin-right: 10px;
                            margin-top: 2px;
                        }
                    }
                a {
                    text-decoration: none;
                    color: white;
                    padding: 0px;
                    &.email {
                        font-size: 18px;
                        span {
                            background-image: url('../img/svg/icon--email.svg');
                            background-size: 25px 25px;
                            display: block;
                            width: 25px;
                            height: 25px;
                            float: left;
                            margin-left: 0px;
                            margin-right: 10px;
                            margin-top: 2px;
                        }
                    }
                }
            }
        }
    }
}
