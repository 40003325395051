.news--popup {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	margin: 0 auto;
	background-color: $dark-blue;
	padding: 40px 20px;
	width: 100%;
	max-width: 700px;
	z-index: 10000;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;

		h2 {
			color: white;
			font-family: $arial;
			font-size: 28px;
			margin: 0 0 25px 0;
		}

		p {
			color: white;
			font-family: $arial;
			font-size: 17px;
		}

		a {
			display: inline-block;
			margin: 12px auto 0 auto;
			background-color: $blue;
			padding: 15px 25px;
			text-align: center;
			color: white;
			text-decoration: none;
			width: auto;
			transition: background-color 0.4s ease;
			-webkit-transition: background-color 0.4s ease;
			-moz-transition: background-color 0.4s ease;
			-o-transition: background-color 0.4s ease;

			&:hover {
				background-color: #029bb5;
			}

		}

		.news--popup-close {
			display: block;
			position: absolute;
			top: 15px;
			right: 15px;
			background-image: url('../img/svg/icon-burger-close-white.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 20px 20px;
			width: 20px;
			height: 20px;
			cursor: pointer;
		}

}

.news--popup-bg {
	display: none;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.50);
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
}


.grecaptcha-badge {
	display: none !important;
}

.container {
		width: 100%;
		max-width: 1500px;
		padding: 0 20px;
		margin: 0 auto;
		box-sizing: border-box;
		font-family: $arial;
		font-weight: $regular;
		// color: black;

		strong {
				font-weight: $bold;
		}
}

// html {
// 	overflow: hidden;
// }

.-right {
	float: right;
}
.-left {
	float: left;
}
.-center {
	margin: 0 auto;
	display: block;
	text-align: center;
	float: none;
}

ol {
		list-style: decimal;
		padding-left: 20px;
		margin-bottom: 20px;
		li {
				padding: 10px 0;
				font-family: $arial;
				font-size: 18px;
				color: $dark-blue;
		}
}

table {
	width: 100% !important;
	border: none;
	border-collapse: collapse;

	th {
		padding: 8px;
		border-bottom: 2px solid #afc1db;
		border-top: 0;
		border-left: 1px solid #afc1db;
		border-right: 0;
		font-family: $arial;
		font-size: 16px;
		color: $dark-blue;
		text-align: left;

		&:first-child {
			border-left: none;
		}
	}

	tr {
		border-bottom: 1px solid #afc1db;
		border-top: 1px solid #afc1db;

		&:first-child {
			border-top: none;
		}

		&:last-child {
			border-bottom: none;
		}
	}

	td {
		padding: 8px;
		border-bottom: none;
		border-top: none;
		border-left: 1px solid #afc1db;
		border-right: 0;
		font-family: $arial;
		font-size: 16px;
		color: $dark-blue;
		text-align: left;

		&:first-child {
			border-left: none;
		}
	}
}

.js--error,
.error {
	color: #fff;
	font-size: 13px;
	margin-bottom: 10px;
}

.js--error {
	display: none;
}

.sitemap {
	padding: 40px 0;
	ul {
		padding-left: 20px;
		margin-bottom: 25px;
		li {
			padding: 5px 0;
			a {
				text-decoration: underline;
				color: $dark-blue;
				font: $arial;
				font-size: 18px;
			}
		}
	}
}

.fourohfour {
	background-color: $blue;
	p {
		font-family: $arial;
		font-size: 18px;
		color: white;
		margin-bottom: 0px;
		padding-top: 35px !important;
		a {
			text-decoration: underline;
			color: white;
			font-weight: bold;
		}
	}
}

#container2 {
		float:left;
		clear: left;
		width:100%;
		overflow: hidden;

		@media screen and (max-width: 955px) {
			float: none;
		}
}
#container1 {
		float:left;
		width:100%;
		background-color: $dark-blue;
		position:relative;

		@media screen and (max-width: 955px) {
			float: none;
			position: static;
		}
}
#col1 {
		float:left;
		width:50%;
		position:relative;
		overflow:hidden;

		@media screen and (max-width: 955px) {
			float: none;
			position: static;
			width: 100%;
			padding: 0 20px;
		}
}
#col2 {
		float:left;
		width:50%;
		position:relative;
		overflow:hidden;

		@media screen and (max-width: 955px) {
			float: none;
			position: static;
			width: 100%;
		}
}

.basic {
	a {
		display: inline-block !important;
	}
}

input {
	-webkit-border-radius:0;
	-webkit-appearance: none;
	border-radius:0;
}

/* This used to work for parent element of button divs */

/* But it is not enough now, below dont hide play button parent div */

*::-webkit-media-controls-panel {
	display: none!important;
	-webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
	display: none!important;
	-webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works */

*::-webkit-media-controls-start-playback-button {
	display: none!important;
	-webkit-appearance: none;
}

.cc-revoke,
.cc-window {
		position: fixed;
		overflow: hidden;
		box-sizing: border-box;
		font-family: $book;
		font-weight: $regular;
		font-size: 16px;
		line-height: 1.5em;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		z-index: 9999
}

.colour-strip {
	@include clearfix;

	width: 100%;
	background-color: $red;
	padding: 20px 0;
	text-align: center;
	@include animate;

	a {
		text-decoration: none;
		color: #fff;
	}

	p {
		text-align: center;
		font-family: $light;
		font-size: 24px;
		margin: 0;
		color: white;
		display: block;
		padding: 0;
		// padding-bottom: 0;
		// padding-top: 10px;
		@include animate;


		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			font-family: $arial !important;
			font-weight: $regular !important;
		}
	}

	&:hover {
		background-color: $dark-blue !important;

		a, p {
			text-decoration: underline;
		}

	}

}

.ie {
	.colour-strip {
		p {
			font-family: $arial !important;
			font-weight: $regular !important;
		}
	}
}
