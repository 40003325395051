.callback {
  &.workplace--advice {

    .callback--form {
      @include flex;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }

      .col {
        display: inline-block;
        width: calc(65% - 50px);
        padding-right: 50px;

        @media screen and (max-width: 800px) {
          width: calc(65% - 30px);
          padding-right: 30px;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0;
          order: 2;
        }

        iframe {
          width: 100%;
          height: auto;
          min-height: 360px;
        }

      }

      .sidebar {
        display: inline-block;
        width: 35%;

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 30px;
          order: 1;
        }

        form {
          .callback--form-section {
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 0;

            .callback--form-left, .callback--form-right {
              max-width: 100%;
              margin-right: 0 !important;
              margin-bottom: 20px;

              input, textarea {
                max-width: calc(100% - 12px);
              }

            }

            &.disclaimer {
              margin-top: 30px;
            }

          }
        }

      }

    }

  }
}
