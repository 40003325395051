h1 {
    font-size: 31px;
    font-weight: $regular;
    color: $red;
    margin-bottom: 15px;
}

h2 {
    font-weight: $regular;
}

h3 {
    font-size: 23px;
}

p {
    font-size: 16px;
    font-weight: $regular;
    margin-bottom: 15px;
}

a {

}
