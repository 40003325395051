.banner {

    &.private {
        width: 100%;
        display: block;

        .container {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          padding-top: 60px;
          padding-bottom: 60px;

          @media screen and (max-width: 750px) {
            padding-top: 30px;
            padding-bottom: 30px;
          }

        }

        .content {
            display: block;
            padding: 40px 40px;
            max-width: 650px;
            width: calc(100% - 160px);
            height: auto;
            @include clearfix;
            background-color: white;
            margin-left: 40px;

            @media screen and (max-width: 750px) {
              width: calc(100% - 80px);
              margin-left: 0;
            }

            @media screen and (max-width: 500px) {
              padding: 25px;
              width: calc(100% - 50px);
            }

            h1 {
                font-family: $arial;
                font-size: 32px;
                color: $dark-blue;
                font-weight: normal;
                margin: 0 0 20px 0;

                @media screen and (max-width: 555px) {
                    font-size: 28px;
                }

                @media screen and (max-width:430px) {
                    font-size: 7.5vw;
                }
            }
            p {
                font-family: $arial;
                font-size: 17px;
                color: $black;
                margin: 0;
                padding-bottom: 20px;

                &:last-child {
                  padding-bottom: 0;
                }

            }

            a {
                display: inline-block;
                font-family: $arial;
                font-size: 17px;
                color: $black;
                text-decoration: none;
                position: relative;
                padding-right: 40px;

                &:after {
                  display: block;
                  content: '';
                  background-image: url('../img/svg/icon--arrow-right-red.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 26px 26px;
                  width: 26px;
                  height: 26px;
                  @include valign;
                  position: absolute;
                  right: 0;
                }
            }
        }
    }
}

.client--services {
  padding: 40px 0;

  @media screen and (max-width: 660px) {
    padding: 40px 0;
  }

  .flex {
    @include flex;
    align-items: flex-start;

    @media screen and (max-width: 660px) {
      flex-wrap: wrap;
    }

    .copy {
      display: inline-block;
      width: 45%;
      padding-right: 25%;

      @media screen and (max-width: 1400px) {
        padding-right: 60px;
        width: calc(70% - 60px);
      }

      @media screen and (max-width: 800px) {
        width: calc(50% - 40px);
        padding-right: 40px;
      }

      @media screen and (max-width: 660px) {
        width: 100%;
        padding-right: 0;
      }

      h2 {
          font-family: $arial;
          color: $dark-blue;
          font-size: 32px;
          font-weight: normal;
          margin: 0 0 20px 0;

          @media screen and (max-width:450px) {
            font-size: 36px;
          }
      }
      p, li {
          font-family: $arial;
          font-size: 17px;
          color: $black;
          padding-bottom: 20px;
          margin: 0;

          &:last-child {
            padding-bottom: 0;
          }

      }
      a {
        color: $black;
        font-weight: $bold;
        text-decoration: underline;
      }
      ul {
        list-style: disc;
        padding-left: 15px;
        li {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 20px;
          }

        }
      }

      .links {
        margin-top: 10px;
        ul {
            display: block;
            list-style: none;
            padding-left: 0;
            li {
                a {
                    text-decoration: none;
                    color: $dark-blue;
                    font-size: 15px;
                    font-family: $arial;
                    font-weight: bold;
                }
                a:before {
                    content: '';
                    left: 0;
                    background-image: url('../img/svg/svg--icon-select-arrow.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 12px;
                    height: 8px;
                    background-size: 12px 8px;
                    margin-right: 7px;
                    display: inline-block;
                    transform: rotate(-90deg);
                }
            }
        }
      }

    }

    .cta {
      display: inline-block;
      width: calc(30% - 80px);
      min-width: 315px;
      background-color: #F7F7F7;
      padding: 40px;

      @media screen and (max-width: 800px) {
        width: calc(50% - 80px);
        min-width: auto;
      }

      @media screen and (max-width: 660px) {
        width: 100%;
        padding: 25px;
      }

      p, a {
        font-family: $arial;
        font-size: 21px;
        color: $black;
        padding-bottom: 20px;

        &:last-child {
          padding-bottom: 0;
        }

      }

      a {
        text-decoration: none;
        display: block;
        position: relative;

        &:after {
          display: block;
          content: '';
          background-image: url('../img/svg/icon--arrow-right-red.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 26px 26px;
          width: 26px;
          height: 26px;
          position: absolute;
          right:0;
          top: -2px;
        }

        &.phone {
          font-size: 32px !important;

          &:before {
            background-image: url('../img/client/icon--tel.svg');
            top: 0 !important;
          }

        }

        &.email {

          &:before {
            background-image: url('../img/client/icon--email.svg');
          }

        }

        &.phone, &.email, &.tel {
          font-size:22px;
          padding-left: 52px;
          padding-bottom: 30px;

          &:before {
            display: inline-block;
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            background-size: 38px 38px;
            width: 38px;
            height: 38px;
            position: absolute;
            left: 0;
            top: -6px;
          }

          &:after {
            display: none;
          }

        }

      }

    }

    .image {
      display: inline-block;
      flex: 1 0 auto;
    }

  }

  &.-image {
    .flex {
      align-items: normal;

      .image {
        width: calc(50% - 40px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media screen and (max-width: 850px) {
          width: calc(50% - 20px);
        }

        @media screen and (max-width: 660px) {
          width: 100%;
          min-height: 250px;
        }

      }

      .copy {
        width: calc(50% - 40px);
        padding: 40px 0 40px 80px;

        @media screen and (max-width: 850px) {
          width: calc(50% - 20px);
          padding: 40px 0 40px 40px;
        }

        @media screen and (max-width: 660px) {
          width: 100%;
          padding: 40px 0 0 0;
        }

      }

    }
  }

}

.client--testimonial {
  background-color: $dark-blue;
  padding: 80px 0;

  @media screen and (max-width: 660px) {
    padding: 40px 0;
  }

  .quote {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    p {
      font-family: $arial;
      font-size: 22px;
      color: white;
      font-weight: normal;
      position: relative;
      padding-left: 25px;
      margin: 0 0 25px 0;

      &:before {
        content: '\201C';
        color: $red;
        font-size: 50px;
        font-family: $arial;
        position: absolute;
        top: -12px;
        left: 0;
      }

      &:after {
        display: inline-block;
        line-height: 0;
        content: '\201D';
        color: $red;
        font-size: 50px;
        font-family: $arial;
        position: absolute;
        bottom: -10px;
        margin-left: 8px;
      }

    }

    h5 {
      font-family: $arial;
      font-size: 17px;
      font-weight: normal;
      color: white;
      margin: 0 0 0 25px;

      span {
        padding: 0 15px;
      }

    }

  }

}

.main_content {
    padding: 40px 0;

    .cta {
        display: block;
        background-color: $dark-blue;
        // padding: 20px;

        width: 100%;
        max-width: 266px;
        float: right;
        margin-top: -142px;

        @media screen and (max-width: 595px) {
            float: none;
            margin: 0 auto 30px auto;
        }

        @media screen and (max-width:555px) {
            margin-top: 0px;
        }

        p,a {
            font-family: $light;
            font-size: 20px;
            color: white;
            font-weight: normal;
        }
        a {
          font-weight: $bold;

          &:last-child {
            margin-bottom: 0;
          }

        }

        h4 {
          font-family: $light;
          font-size: 22px;
          color: white;
          font-weight: normal;
          margin-bottom: 15px;
        }

        .padded {
            padding: 20px;
        }

        div.phone, .email {
            font-family: $medium;
            font-size: 24px;
            color: white;
            font-weight: normal;
            margin-bottom: 15px;
            text-decoration: none;
            display: block;

            span {
              display: block;
              background-image: url('../img/svg/icon--phone-green.svg');
              background-size: 25px 25px;
              background-repeat: no-repeat;
              background-position: center;
              width: 25px;
              height: 25px;
              float: left;
              margin-right: 10px;
            }

        }

        .email {

          span {
            background-image: url('../img/svg/icon--email.svg');
          }

        }

        .phone {
            font-family: $medium;
            font-size: 20px;
            color: white;
            text-decoration: none;
            display: block;
            padding: 0 0 20px 0;

            span {
                display: block;
                background-image: url(../img/svg/icon--phone-green.svg);
                background-size: 25px 25px;
                background-repeat: no-repeat;
                background-position: center;
                width: 25px;
                height: 25px;
                float: left;
                margin-right: 10px;
            }
        }

        .custom--link {
          font-family: $medium;
          font-size: 20px;
          color: white;
          text-decoration: none;
          display: block;
          padding: 0 40px 0 0;
          width: calc(100% - 40px);
          position: relative;
          margin-bottom: 20px;

          &:after {
              display: inline-block;
              content: '';
              background-image: url('../img/svg/icon--right-arrow-white.svg');
              background-size: 26px 26px;
              width: 26px;
              height: 26px;
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
          }
        }
    }

    .text {
        width: 43%;

        &.half-width {
            width: 50%;

            @media screen and (max-width: 700px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 700px) {
            width: 100%;
        }

        h2 {
            font-family: $light;
            color: $dark-blue;
            font-size: 53px;
            font-weight: normal;
            border-bottom: 1px solid $red;
            padding-bottom: 15px;
            margin-bottom: 35px;

            @media screen and (max-width: 595px) {
                margin-top: 30px;
            }
            @media screen and (max-width:450px) {
              font-size: 36px;
            }
        }
        p, a {
            font-family: $arial;
            font-size: 18px;
            color: $dark-blue;
        }
        a {
          font-weight: $bold;
          text-decoration: underline;
        }
        ul {
          list-style: disc;
          padding-left: 15px;
          li {
            padding: 8px 0;
            color: $dark-blue;
            font-size: 18px;
          }
        }
    }

    .links {
        width: 41%;
        margin-top: 140px;

        @media screen and (max-width: 700px) {
            width: 100%;
        }

        @media screen and (max-width: 700px) {
            margin-top: 20px;
        }
        ul {
            display: inline-block;
            margin-right: 23px;
            vertical-align: top;
            li {
                margin-bottom: 10px;
                a {
                    text-decoration: none;
                    color: $dark-blue;
                    font-size: 15px;
                    font-family: $arial;
                    font-weight: bold;
                }
                a:before {
                    content: '';
                    left: 0;
                    background-image: url('../img/svg/icon--link-arrow-red.svg');
                    background-size: cover;
                    width: 6px;
                    height: 10px;
                    margin-right: 7px;
                    display: inline-block;
                }
            }
        }
    }
    .clearfix {
        @include clearfix;
    }
}

.home_tra {
    .private {
        /* background-image: url('../img/private-quote.jpg'); */
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        height: 715px;
        width: 50%;

        @media screen and (max-width: 920px) {
            display: none;
        }
    }

    .tra.priv {
        .tra_container.quote {
            margin-left: 12%;
            padding: 0;

        @media screen and (max-width: 920px) {
                margin-left: 0%;
                padding: 0 20px;
        }

        p {
                font-family: $light;
                font-size: 32px;
                color: white;
                font-weight: normal;
                margin-bottom: 25px;
                margin-top: 80px;
                width: 415px;

                @media screen and (max-width: 920px) {
                        width: 100%;
                        margin-top: 0px;
                }

                &:before {
                        content: '"';
                        color: $red;
                        font-weight: bold;
                        font-size: 35px;
                        line-height: 0;
                }

                &:after {
                        content: '"';
                        color: $red;
                        font-weight: bold;
                        font-size: 35px;
                        line-height: 0;
                }
        }

            h5 {
                font-family: $arial;
                font-size: 18px;
                color: white;
                margin-bottom: 30px;
                font-weight: normal !important;
            }
        }
    }
}
