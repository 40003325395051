// selects all IE <= 9
.ie {
  .branches_list {
    .branch {
      .padding {
        a {
          font-family: $opensans !important;
          font-weight: $normal !important;
        }
      }
    }
  }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .branches_list {
    .branch {
      .padding {
        a {
          font-family: $opensans !important;
          font-weight: $normal !important;
        }
      }
    }
  }
}


.branches {

    .cta {
        margin-top: -200px;

        @media screen and (max-width: 595px) {
            margin-top: -20px;
        }

        p {
            font-family: $light;
            font-size: 14px;
            color: white;
            padding: 0 20px 0 20px;
        }

        a {
            font-size: 15px !important;
            margin-top: 23px !important;

            h5 {
                font-family: $medium;
                font-size: 28px;
                color: white;
                font-weight: normal;
            }
        }
    }

    .text {

        &.-right {
            margin-top: 108px;
        }
    }

    .text.second {
        margin-top: 98px;

        @media screen and (max-width: 595px) {
            margin-top: 0;
        }
    }
}

.branches_list {

    .branch {
        display: inline-block;
        width: 33%;
        height: 100%;
        min-height: 332px;
        background-color: white;
        vertical-align: top;

        @media screen and (max-width: 925px) {
            width: 32%;
            min-height: 381px;
        }

        @media screen and (max-width:690px) {
            width: 49%;
        }

        @media screen and (max-width:465px) {
            width: 100%;
            display: block;
            min-height: 100%;
        }

        .padding {
            padding: 20px;

            p {
                font-family: $arial;
                font-size: 18px;
                font-weight: normal;
                color: $dark-blue;
            }

            a:first-of-type {
                margin-top: 0px !important;
                font-family: $arial;
                font-weight: bold;
                font-size: 23px;
                color: $blue;
                margin-bottom: 10px;
            }

            a {
                display: block;
                text-decoration: none;
                color: $dark-blue;
                margin-top: 20px;

                &.phone {
                    font-family: $medium;
                    font-size: 25px;

                    span {
                        background-image: url('../img/svg/icon--phone-green.svg');
                        background-size: 25px 25px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin-right: 8px;
                        margin-top: 2px;
                    }
                }

                &.email {
                    font-family: $medium;
                    font-size: 18px;

                    span {
                        background-image: url('../img/svg/icon--email.svg');
                        background-size: 25px 25px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .branch:nth-child(odd) {
        background-color: #F5F5F5;
    }
}

.all_branch_map {

    @media screen and (max-width: 595px) {
        display: none;
    }

    .branch_map {
        width: 100%;
        height: 1020px;
    }
}
