@keyframes rotate-right {
    0% {
        transform: rotate(-20deg);
        -webkit-transform: rotate(-20deg);
    	opacity: 0;
    }
    30% {
    	opacity: 1;
    }
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
}

@keyframes rotate-left {
    0% {
        transform: rotate(20deg);
        -webkit-transform: rotate(20deg);
    	opacity: 0;
    }
    30% {
    	opacity: 1;
    }
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
}

.core_principles {

  .cls-1 {
    fill: #00a1be;
  }

  .cls-1, .cls-3, .cls-4 {
    stroke: #fff;
    stroke-miterlimit: 10;
  }

  .cls-1, .cls-4 {
    stroke-width: 12.44px;
  }

  .cls-2, .cls-3 {
    fill: #fff;
  }

  .cls-3 {
    stroke-width: 7.97px;
  }

  .cls-4 {
    fill: #7ab929;
  }

  .cls-5 {
    fill: #e62a3f;
  }

}
