.mylebc--form {
  background-color: #ededed;
  padding: 60px 0;

  @media screen and (max-width: 750px) {
    padding: 40px 0;
  }

  .mylebc--form-intro {
    text-align: center;
    display: block;
    width: 100%;
    max-width: 750px;
    margin: 0 auto 60px auto;

    @media screen and (max-width: 750px) {
      margin-bottom: 30px;
    }

    h2 {
      font-family: $medium;
      font-size: 27px;
      color: $dark-blue;
      margin: 0 0 20px 0;
    }

    p {
      font-family: $arial;
      font-size: 14px;
      color: $dark-blue;
      padding-bottom: 15px;
      margin: 0;

      a {
        font-weight: bold;
        color: $dark-blue;
        text-decoration: underline;
      }

      &:last-child {
        padding-bottom: 0;
      }

    }

  }

  .mylebc--form-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 25px;

    input {
      display: inline-block;
      background-color: white;
      border: 1px solid #c2c2c2;
      padding: 15px 15px;
      font-family: $arial;
      font-size: 14px;
      color: black;
      width: calc(50% - 42px);
      margin-bottom: 20px;
      @include animate;

      @media screen and (max-width: 600px) {
        width: calc(100% - 32px);
        margin-right: 0 !important;
      }

      &:nth-child(odd) {
        margin-right: 20px;
      }

      &:focus {
        border-color: $blue;
        outline:none;
      }

    }

  }

  input[type="submit"] {
    display: block;
    background-color: $blue;
    border: none;
    font-family: $light;
    font-size: 20px;
    color: white;
    text-align: center;
    padding: 15px;
    margin: 0 auto;
    @include animate;

    &:focus {
      outline:none;
    }

    &:hover {
      background-color: $dark-blue;
    }

  }

  .mylebc--form-success {
    text-align: center;

    h3 {
      font-family: $medium;
      font-size: 20px;
      color: $dark-blue;
      margin: 0 0 15px 0;
    }

    p {
      font-family: $arial;
      font-size: 14px;
      color: $dark-blue;
      padding: 20px;

      &:last-child {
        padding-bottom: 0;
      }

      a {
        color: $dark-blue;
        text-decoration: underline;
        font-weight: bold;
      }

    }

  }

}
