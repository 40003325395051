.single {

    .clearfix {
        @include clearfix;
    }

    .container {
        position: relative;
    }

    .branch-sidebar {
        width: 266px;
        float: right;
        transform: translateY(-140px);

        @media screen and (min-width: 950px) {
            position: absolute;
            right: 0;
        }

        @media screen and (max-width: 950px) {
            transform: translateY(0);
        }

        @media screen and (max-width: 690px) {
            float: none;
            margin: 0 auto;
        }

        .cta {
            margin: 0;
            float: none;
        }

        .opportunities {
            display: block;
            background-color: $blue;
            padding: 18px 20px 18px 20px;
            text-align: center;
            width: 100%;
            max-width: 226px;
            margin-top: 40px;

            @media screen and (max-width: 595px) {
                margin: 0 auto;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            a {
                text-decoration: none;
                font-family: $medium;
                font-size: 17px;
                color: white;

                span {
                    background-image: url('../img/svg/icon--right-arrow-white.svg');
                    background-size: 26px 26px;
                    display: block;
                    width: 26px;
                    height: 26px;
                    float: right;
                    margin-left: 15px;
                    margin-top: -3px;
                }
            }
        }
    }

    .text {
        float: left;

        @media screen and (max-width: 690px) {
            float: none;
            margin-top: 30px;
            width: 100% !important;
        }
    }
}

.staff_list {

    .staff {
        width: 167px;
        height: 300px;
        vertical-align: top;
        display: inline-block;
        border-bottom: 1px solid $red;
        padding-bottom: 12px;
        margin-bottom: 30px;
        text-align: left;
        position: relative;
        margin-right: 20px;

        @media screen and (max-width: 735px) {
            margin-right: 20px;
        }

        h5 {
            font-family: $arial;
            font-size: 15px;
            color: $blue;
            font-weight: bold;
            margin-top: 5px;
        }

        p {
            font-family: $arial;
            font-size: 15px;
            color: $dark-blue;
            margin-top: 6px;
        }

        .link {
            font-family: $arial;
            font-size: 15px;
            color: $dark-blue;
            display: block;
            text-decoration: none;
            float: right;
            margin-top: 5px;
            position: absolute;
            bottom: 10px;
            right: 0;


            span {
                background-image: url('../img/svg/icon--arrow-right-dark-blue.svg');
                background-size: 26px 26px;
                height: 26px;
                width: 26px;
                display: block;
                float: right;
                margin-left: 10px;
                margin-top: -4px;
            }
        }

        img {
            width: 167px;
            height: auto;
        }
    }

    .stretch {
        width: 100%;
        display: inline-block;
        font-size: 0;
        line-height: 0
    }
}


.branch_map {

    .branch_info {
        display: block;
        width: 330px;
        height: auto;
        position: absolute;
        top: 40px;
        right: 40px;
        background-color: rgba(44, 59, 84, 0.9);
        overflow: auto;
        z-index: 100;

        @media screen and (max-width:595px) {
            width: 100%;
            position: static;
            background-color: $dark-blue;
        }

        .padding {
            padding: 30px;

            .logo {
                background-image: url('../img/svg/logo--white.svg');
                background-size: 135px 66px;
                display: block;
                width: 135px;
                height: 66px;
                margin-bottom: 20px;
            }

            p {
                font-family: $arial;
                font-size: 18px;
                font-weight: bold;
                color: white;
                line-height: 1.7;
            }

            a {
                text-decoration: none;
                color: white;
                font-family: $medium;
                display: block;

                &.tel {
                    font-size: 25px;
                    margin-top: 20px;

                    span {
                        background-image: url('../img/svg/icon--phone-green.svg');
                        background-size: 26px 26px;
                        display: block;
                        width: 26px;
                        height: 26px;
                        float: left;
                        margin-right: 10px;
                    }
                }

                &.email {
                    font-size: 18px;
                    margin: 20px 0;

                    span {
                        background-image: url('../img/svg/icon--email.svg');
                        background-size: 26px 26px;
                        display: block;
                        width: 26px;
                        height: 26px;
                        float: left;
                        margin-right: 10px;
                    }
                }

                &.directions {
                    margin-top: 30px;
                }
            }
        }
    }

    .cms-map {
        @media screen and (max-width: 595px) {
            height: 300px !important;
        }
    }
}

.branch-map-container {
    position: relative;
}

    .branch-map {
        width: 100%;
        height: 600px;
    }
