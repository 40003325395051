.nav {
    width: 100%;
    display: block;
    background-color: white;
    border-bottom: 1px solid #D5D5D5;

    @media screen and (max-width: 825px) {
        z-index: 100;
        padding: 0;
        overflow: scroll;

        .container {
          margin: 0;
          padding: 0;
        }

    }

    ul {
        @include clearfix;
        display: flex;
        // align-items: center;
        position: relative;
        width: 100%;
        justify-content: space-between;

        @media screen and (max-width: 1370px) {

        }

        @media screen and (max-width: 825px) {
            display: none;
            float: none;

            &.show {
                display: block;
                width: 100%;
                position: absolute;
                top: 88px;
                z-index: 2000;
                background-color: white;
            }
        }

        li {
          display: inline-block;
          margin-left: 2%;
          // background-color: pink;

            @media screen and (max-width: 825px) {
                float: none;
                display: block;
                text-align: center;
                border-bottom: 1px solid #D5D5D5;
                margin-left: 0;
                position: relative;
            }

            input[type=checkbox] {
              /* hide native checkbox */
              position: absolute;
              opacity: 0;
              display: none;

              @media screen and (max-width: 825px) {
                display: block;
              }

            }

            input[type=checkbox]:checked + label + ul,
          	input[type=checkbox]:checked + label:nth-of-type(n) + ul {
          		display: block;
          	}

            label {
              display: none;
              width: 47px;
              height: 47px;
              position: absolute;
              right: 0;
              top: 0;
              padding: 0;
              margin: 0;
              background-color: rgba(0,0,0,0.15);
              cursor: pointer;

              &:after {
                display: block;
                content: '';
                background-image: url('../img/svg/icon--link-arrow-red.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20px 20px;
                width: 20px;
                height: 20px;
                position: absolute;
                @include center;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                @include animate;
              }

              @media screen and (max-width: 825px) {
                display: inline-block;
              }

              &.active {
                &:after {
                  transform: translateY(-50%) rotate(90deg);
                  -webkit-transform: translateY(-50%) rotate(90deg);
                  -ms-transform: translateY(-50%) rotate(90deg);
                }
              }

            }

            &.about {
              position: relative;

              .sub--nav {
                width: 170%;
                min-width: 200px;
                padding: 0;

                @media screen and (max-width: 825px) {
                  width: 100%;
                  background-color: $grey;
                }

                ul {
                  display: block;
                  width: 100%;
                  position: static !important;

                  @media screen and (max-width: 825px) {
                    background-color: #E3E3E3;
                  }

                  li {
                    display: block;

                    &:nth-child(2) {
                      a {
                        padding-top: 10px;
                        padding-bottom: 10px;
                      }
                    }

                    a {
                      position: relative;
                      top: 0;
                      transform: translateY(0);
                      padding: 25px 25px 25px 40px;
                      @include animate;
                      text-align: left;

                      @media screen and (max-width: 825px) {
                        width: auto;
                        display: inline-block;
                        padding: 20px 20px 20px 40px !important;
                      }

                      &:before {
                        display: inline-block;
                        content: '';
                        background-image: url('../img/svg/arrow--blue.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 8px 12px;
                        width: 8px;
                        height: 12px;
                        @include valign;
                        position: absolute;
                        left: 18px;
                      }

                      &:hover {
                        color: $red;
                      }

                    }

                  }

                }

              }

            }

            &.mylebc {
              // position: absolute;
              right: 0;
              margin: 0;
              padding: 0;

              @media screen and (max-width: 1370px) {
                position: static;
                margin-left: 20px;
              }

              @media screen and (max-width: 825px) {
                position: relative;
                margin: 0;
                padding: 0;
              }

              a {
                padding: 15px 25px;
                color: white;
                font-weight: bold;
                text-align: center;
                background-color: $blue;

                @media screen and (max-width: 825px) {
                  padding: 15px 0;
                }

                &:hover {
                  background-color: $red;
                  color: white;
                }

              }

              .sub--nav {
                // width: 500px;
                width: auto;
                // left: -411px;
                left: auto;
                right: 0;
                align-items: flex-start;

                @media screen and (max-width: 1370px) {
                  width: auto;
                  // left: 0;
                }

                .col {
                  width: 100%;

                  p, a {
                    @media screen and (max-width: 825px) {
                      text-align: center;
                    }
                  }

                  a {
                    background: none;
                  }

                }

              }

            }

            .sub--nav {
              display: none;
              position: absolute;
              background-color: #E3E3E3;
              padding: 30px;
              width: calc(100% - 60px);
              left: 0;
              top: 67px;
              z-index: 2000;

              @media screen and (max-width: 1370px) {
                top: 64px;
              }

              @media screen and (max-width: 1133px) {
                top: 84px;
              }

              @media screen and (max-width: 1000px) {
                width: calc(100% - 30px);
                padding: 15px;
              }

              @media screen and (max-width: 825px) {
                position: static;
              }

              .col {
                display: inline-block;
                width: calc(100% / 3 - 13px);
                margin-right: 20px;

                &:last-child {
                  margin-right: 0;

                  @media screen and (max-width: 825px) {
                    margin-bottom: 0 !important;
                  }

                }

                @media screen and (max-width: 825px) {
                  width: 100% !important;
                  padding: 0 !important;
                  margin: 0 0 20px 0 !important;
                }

                .logo {
                  display: block;
                  background-image: url('../img/mylebc/logo--bionic-advice-dark.svg') !important;
                  background-repeat: no-repeat !important;
                  background-position: center;
                  background-size: 200px 36px !important;
                  width: 200px;
                  height: 36px;
                  margin-bottom: 15px;

                  @media screen and (max-width: 825px) {
                    margin: 0 auto 15px auto;
                    text-align: center;
                  }

                }

                h4 {
                  font-family: $arial;
                  font-size: 20px;
                  color: $black;
                  font-weight: normal;
                  margin: 0 0 20px 0;

                  @media screen and (max-width: 825px) {
                    margin: 10px 0 15px 0;
                  }

                }

                p, a, li {
                  font-family: $arial;
                  font-size: 15px;
                  color: $black;
                  font-weight: normal;
                  margin: 0;
                  text-decoration: none;
                  padding: 0;
                  display: block;
                  text-align: left;
                  top: 0;
                  transform: translateY(0);
                }

                ul {
                  display: block;
                  align-items: flex-start;
                  position: static;

                  &:after { display: none }

                  @media screen and (max-width: 825px) {
                    background-color: #E3E3E3;
                    text-align: center;
                  }

                  li {
                    display: block;
                    margin: 0 0 15px 0;
                    position: relative;

                    &:last-child { margin-bottom: 0 }

                    @media screen and (max-width: 825px) {
                      padding: 0;
                      margin: 0;
                      border: none;
                      text-align: center;

                      &:last-child {
                        border-bottom: 0;
                      }

                    }

                    &.selected {
                      a {
                        color: $red;
                      }
                    }

                    a {
                      text-decoration: none;
                      padding: 0 0 0 15px;
                      @include animate;
                      background: none !important;
                      position: relative;

                      &:hover { color: $red }

                      @media screen and (max-width: 825px) {
                        padding: 8px 0 8px 15px;
                        text-align: center;
                        display: inline-block;
                      }

                      &:before {
                        display: inline-block;
                        content: '';
                        background-image: url('../img/svg/arrow--blue.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 8px 12px;
                        width: 8px;
                        height: 12px;
                        @include valign;
                        position: absolute;
                        left: 0;
                      }

                    }

                  }
                }

                .cta {
                  background-color: $dark-blue;
                  padding: 40px;

                  @media screen and (max-width: 1100px) {
                    padding: 20px;
                  }

                  h2, h3, h4, h5, p, li, a {
                    color: white;
                  }

                  p, a {
                    font-size: 21px;

                    @media screen and (max-width: 825px) {
                      font-size: 20px;
                    }

                  }

                  a {
                    text-decoration: none;
                    display: block;
                    position: relative;
                    padding-right: 30px;

                    &:hover {
                      background: none !important;
                    }

                    &:after {
                      display: block;
                      content: '';
                      background-image: url('../img/svg/icon--arrow-right-red.svg');
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: 26px 26px;
                      width: 26px;
                      height: 26px;
                      position: absolute;
                      right:0;
                      top: -2px;
                    }

                  }

                  .tel {
                    font-size: 31px;
                    position: relative;
                    margin: 25px 0 20px 0;
                    padding: 0 0 0 52px;

                    @media screen and (max-width: 825px) {
                      font-size: 24px;
                      padding-left: 40px;
                    }

                    &:before {
                      display: inline-block;
                      content: '';
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: 38px 38px;
                      width: 38px;
                      height: 38px;
                      @include valign;
                      position: absolute;
                      left: 0;
                      background-image: url('../img/client/icon--tel.svg');

                      @media screen and (max-width: 825px) {
                        width: 30px;
                        height: 30px;
                        background-size: 30px 30px;
                      }

                    }

                    &:after {
                      display: none;
                    }

                  }

                  .email {
                    font-size: 22px;
                    position: relative;
                    margin-bottom: 30px;
                    padding: 0 0 0 52px;

                    @media screen and (max-width: 825px) {
                      font-size: 18px;
                      padding-left: 40px;
                    }

                    &:before {
                      display: inline-block;
                      content: '';
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: 38px 38px;
                      width: 38px;
                      height: 38px;
                      @include valign;
                      position: absolute;
                      left: 0;
                      background-image: url('../img/client/icon--email.svg');

                      @media screen and (max-width: 825px) {
                        width: 30px;
                        height: 30px;
                        background-size: 30px 30px;
                      }

                    }

                    &:after {
                      display: none;
                    }

                  }

                }

              }

            }

            @media screen and (min-width: 826px) {
              &:hover {

                .sub--nav {
                  @include flex;
                }

              }
            }

            a {
                font-family: $arial;
                font-size: 15px;
                color: $black;
                font-weight: $regular;
                text-decoration: none;
                display: block;
                padding: 25px 25px;
                text-align: center;
                @include valign;

                @media screen and (max-width: 1370px) {
                  padding: 25px 10px;
                }

                @media screen and (max-width: 825px) {
                    padding: 15px 0;
                    display: block;
                    top: 0;
                    transform: translateY(0);
                }

                &:hover, &.selected {
                    background-color: #E3E3E3;
                    color: #333333;
                }

                &.home--icon {
                  display: block;
                  width: 20px;
                  height: 20px;
                  background-image: url('../img/svg/icon-home.svg');
                  background-size: 20px 20px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  position: relative;
                  top: -2px;
                  padding: 0;
                  background-color: white !important;
                  @include valign;

                  @media screen and (max-width: 825px) {
                    margin: 0 auto;
                    position: static;
                    padding: 10px 0;
                    top: 0;
                    transform: translateY(0);
                  }

                }

            }
            &.only_mobile {
              display: none;

              @media screen and (max-width: 825px) {
                display: block;
                padding: 10px 0;
                  .search_form {
                    display: inline-block;

                    input[type="search"] {
                      font-family: $arial;
                      font-size: 13px;
                      border: none;
                      border: 1px solid $dark-blue;
                      padding: 3px;
                    }
                    input[type="search"]:focus {
                      outline: none !important;
                    }

                    input[type="submit"] {
                      background-image: url('../img/svg/icon--search.svg');
                      background-size: 10px 10px;
                      background-repeat: no-repeat;
                      background-position: center;
                      background-color: $red;
                      border: 1px solid $red;
                      display: inline-block;
                      width: 23px;
                      height: 23px;
                      vertical-align: middle;
                      -webkit-border-radius:0 !important;
                      border-radius:0 !important;
                      margin-left: -5px;
                    }
                  }

              }
            }

            &:first-child {
              margin-left: 0px;
            }

        }
    }

    &.sticky {
      position: fixed;
      z-index: 2000;
      top: 74px;

        @media screen and (max-width: 825px) {
            position: static;
            top: 0;
        }
    }
}
