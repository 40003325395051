.chat--now {
  display: block;
  background-image: url('../img/mylebc/chat.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 220px 48px;
  width: 220px;
  height: 48px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  right: 20px;

  @media screen and (max-width: 750px) {
    display: none;
  }

}

.down {
  // display: block;
  // position: absolute;
  // // top: -28px;
  // top: 810px;
  // @include center;
  // background-image: url('../img/mylebc/arrow--down.svg');
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 55px 55px;
  // width: 55px;
  // height: 55px;
  // z-index: 100;
}

.mylebc--banner {
  background-color: $dark-blue;
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 700px;
  overflow: hidden;

  @media screen and (max-height: 850px) {
    // height: 530px;
    height: auto;
  }

  @media screen and (max-width: 750px) {
    height: auto;
    padding: 40px 0;
  }

  #mylebcvideo {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
		background-size: cover;
		background-position: center center;

		@media screen and (max-width: 750px) {
			display: none;
		}

  }

  .mobile-vid {
		display: none;
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
		background-size: cover;
		background-position: center center;

		@media screen and (max-width: 750px) {
			display: block;
		}

	}

  .container {
    z-index: 100;
    position: relative;
    height: 700px;

    @media screen and (max-width: 750px) {
      height: auto;
    }

    @media screen and (max-height: 810px) {
      // height: 530px;
      height: auto;
      padding: 30px 20px;
    }

  }

  .banner--copy {
    width: 45%;
    display: inline-block;
    max-width: 435px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-height: 850px) {
      position: static;
      transform: translateY(0);
    }

    @media screen and (max-width: 750px) {
      width: 100%;
      max-width: 100%;
      top: 0;
      transform: translateY(0);
    }

    .logo {
      display: block;
      background-image: url('../img/mylebc/logo--bionic-advice.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 350px 64px;
      width: 350px;
      height: 64px;
      margin-bottom: 23px;
    }

    h1 {
      font-family: $book;
      font-size: 27px;
      line-height: 35px;
      color: white;
      font-weight: $regular;
      margin: 0 0 20px 0;
    }

    p {
      font-family: $arial;
      font-size: 14px;
      line-height: 17px;
      color: white;
      font-weight: $regular;
      margin-bottom: 20px;

      small {
        font-size: 10px;
      }

      a {
        color: white;
        text-decoration: underline;
        font-weight: $bold;
      }

    }

    a.watch {
      display: inline-block;
      background-color: $blue;
      text-align: center;
      font-family: $arial;
      font-size: 14px;
      color: white;
      text-decoration: none;
      font-weight: $bold;
      padding: 15px 20px 15px 20px;
      position: relative;

      &.afterglow {
        padding: 15px 20px 15px 45px;

        &:before {
          display: inline-block;
          content: '';
          background-image: url('../img/svg/svg--icon-play.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 19px 19px;
          width: 19px;
          height: 19px;
          position: absolute;
          left: 15px;
          top: 13px;
        }

      }

    }

  }

  .banner--img {
    display: inline-block;
    width: 50%;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 750px) {
      width: 100%;
      max-width: 100%;
      top: 0;
      transform: translateY(0);
      position: static;
      margin-top: 25px;
      max-width: 500px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      float: right;
    }

  }

  &.protection {
    height: auto;
    padding: 60px 0;

    .container {
      height: auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .banner--copy {
      position: static;
      transform: translateY(0);

      a.watch {
        display: inline-block;
        background-color: $blue;
        padding: 15px 20px 15px 20px;
        position: relative;
        @include animate;

        &:after {
          position: absolute;
          left: auto;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: $red;
        }

      }

    }

  }

}

.mylebc--section {
  position: relative;
  padding: 45px 0;
  background-color: white;

  @media screen and (max-width: 750px) {
    padding: 50px 0;
  }

  &:nth-child(3) {
    .mylebc--flex {
      .col {
        .img {
          img {
            max-width: 350px;
          }
        }
      }
    }
  }

  &:nth-child(even) {
    background-color: #e8e8e8;

    .mylebc--flex {
      flex-direction: row-reverse;

      .col {

        &.copy {
          padding-right: 0;
          padding-left: 60px;

          a.more {
            &:after {
              background-image: url('../img/mylebc/arrow--right-w.svg');
            }
          }
        }

        &.img {
          padding-left: 0;
          padding-right: 60px;
        }

      }

    }

  }

  .mylebc--flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .col {
      display: inline-block;
      width: 50%;

      @media screen and (max-width: 750px) {
        width: 100% !important;
        padding: 0 !important;
      }

      &.copy {
        width: calc(50% - 60px);
        padding-right: 60px;

        h2 {
          font-family: $medium;
          font-size: 32px;
          color: $dark-blue;
          margin: 0 0 15px 0;
        }

        h4 {
          font-family: $light;
          font-size: 22px;
          color: $dark-blue;
          margin: 0 0 15px 0;
        }

        p {
          font-family: $arial;
          font-size: 14px;
          color: $dark-blue;
          font-weight: $regular;
          margin: 0 0 20px 0;
        }

        .app--store {
          margin: 15px 0 12px 0;

          a {
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 150px 47px;
            width: 150px;
            height: 47px;

            &.apple {
              background-image: url('../img/mylebc/apple.svg');
              margin-right: 10px;
            }

            &.google {
              background-image: url('../img/mylebc/google.svg');
            }

          }

        }

        a.more {
          font-family: $medium;
          font-size: 14px;
          color: $blue;
          text-decoration: none;

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/mylebc/arrow--right.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 28px 28px;
            width: 28px;
            height: 28px;
            position: relative;
            left: 11px;
            top: 9px;
          }

        }

      }

      &.img {
        width: calc(50% - 60px);
        padding-left: 60px;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 auto;
          text-align: center;
          max-width: 250px;
        }

      }

    }

  }

}

.bionic--pods {
  background-color: #e8e8e8;
  padding: 60px 0 40px 0;

  h3 {
    font-family: $book;
    font-size: 34px;
    color: $dark-blue;
    text-align: center;
    margin: 0 0 60px 0;
  }

  .flex {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;

    .col {
      background-color: $dark-blue;
      display: inline-block;
      width: calc(100% / 3 - 74px);
      padding: 30px;
      margin: 0 20px 20px 0;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      @media screen and (max-width: 810px) {
        width: calc(100% / 2 - 70px);
        margin-right: 20px !important;

        &:nth-child(even) {
          margin-right: 0 !important;
        }

      }

      @media screen and (max-width: 540px) {
        width: 100%;
        margin-right: 0px !important;

        &:nth-child(even) {
          margin-right: 0 !important;
        }

      }

      .icon {
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        max-width: 115px;
        height: auto;
      }

      h2 {
        font-family: $medium;
        font-size: 27px;
        color: white;
        margin: 25px 0 15px 0;
        text-align: left;
      }

      h3 {
        font-family: $light;
        font-size: 18px;
        color: white;
        margin: 0 0 15px 0;
        text-align: left;
      }

      p {
        font-family: $arial;
        font-size: 14px;
        color: white;
        text-align: left;
        margin-bottom: 0;
      }

      .buttons {
        margin-top: 32px;

        a {
          display: block;
          font-family: $medium;
          font-size: 14px;
          color: white;
          text-decoration: none;
          position: relative;
          margin-bottom: 15px;

          &:hover {
            &:after {
              background-image: url('../img/svg/icon--right-arrow-white.svg');
            }
          }

          &:last-child { margin-bottom: 0 }

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/svg/icon--arrow-right-red.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px 15px;
            width: 15px;
            height: 15px;
            position: absolute !important;
            @include valign;
            margin-left: 9px;
            @include animate;
          }

        }

      }

    }

  }

}
