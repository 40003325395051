// selects all IE <= 9
.ie {
    .colour_banner {
        h1 {
            font-family: $opensans !important;
            font-weight: $lighte !important;
            line-height: 65px;
        }
        h2 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
    }
}

// selects IE10 & IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .colour_banner {
        h1 {
            font-family: $opensans !important;
            font-weight: $lighte !important;
            line-height: 65px;
        }
        h2 {
          font-family: $opensans !important;
          font-weight: $lighte !important;
        }
    }
}

.colour_banner {
    background-color: $blue;
    padding: 40px 0;

    h1 {
        font-family: $light;
        font-size: 55px;
        color: white;
        font-weight: normal;
        margin: 0;
        padding-right: 37%;

        @media screen and (max-width: 595px) {
          padding-right: 0px;
        }
        @media screen and (max-width:430px) {
            font-size: 15vw;
        }
    }
}

.main_content.retirement {
    .service_cta {
      width: 270px;

      @media screen and (max-width: 595px) {
        width: 100%;
      }
      .cta {
        @media screen and (max-width: 595px) {
          margin-top: 0px;
        }
      }
    }
    .text {
        width: 50%;

        @media screen and (max-width: 595px) {
            width: 100%;
        }
        a {
            font-family: $medium;
            font-size: 17px;
            color: $dark-blue;
            text-decoration: none;
            margin-top: 30px;
            display: block;
            span {
                background-image: url('../img/svg/icon--arrow-right-red.svg');
                background-size: 26px 26px;
                display: block;
                width: 26px;
                height: 26px;
                float: left;
                margin-right: 10px;
                margin-top: -3px;
            }
        }
        .cta--link {
          display: inline-block;
          background-color: #00ACC9;
          text-align: center;
          font-family: Arial,sans-serif;
          font-size: 14px;
          color: white;
          text-decoration: none;
          font-weight: 700;
          padding: 15px 20px 15px 20px;
          position: relative;
          float: none;
          margin-top: 0;
        }
    }

    .guide_pod {
        display: block;
        background-color: #F5F5F5;
        float: right;
        width: 100%;
        max-width: 266px;
        margin-top: 30px;
        position: relative;

        @media screen and (max-width: 595px) {
            float: none;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .heading {
            p {
                font-family: $book;
                font-size: 23px;
                color: $dark-blue;
                font-weight: normal;
                padding: 20px 91px 5px 20px;

                strong {
                    font-family: $medium;
                }
            }
        }

        p {
            font-family: $arial;
            font-size: 16px;
            color: $dark-blue;
            // padding: 5px 20px 0 20px;
            padding: 5px 20px 0 20px;
        }

        img {
            position: absolute;
            right: 15px;
            top: 10px;
        }

        a {
          &.report--dl {
            text-decoration: none;
            font-family: $medium;
            font-weight: $bold;
            font-size: 20px;
            color: $dark-blue;
            display: block;
            padding: 10px 20px 20px 20px;

            span {
              display: block;
              background-image: url('../img/svg/icon--arrow-right-dark-blue.svg');
              background-size: 26px 26px;
              background-repeat: no-repeat;
              width: 26px;
              height: 26px;
              float: right;
            }

          }
        }

        input[type="text"] {
          margin-bottom: 8px !important;
        }

        input[type="email"], input[type="text"] {
            display: inline-block;
            width: 181px;
            height: 26px;
            border: 1px solid #CCCCCC;
            padding: 5px 5px 5px 10px;
            color: #000000;
            font-family: $arial;
            font-size: 14px;
            margin: 0 20px 20px 20px;
            outline: none;
        }

        input[type="submit"] {
            background-image: url('../img/svg/icon--submit-blue.svg');
            background-size: 25px 25px;
            display: inline-block;
            background-repeat: no-repeat;
            width: 25px;
            height: 25px;
            border: none !important;
            background-color: transparent !important;
            float: right;
            margin-top: -50px;
            margin-right: 15px;
            outline: none;
        }

        .download {
            display: inline-block;
            background-color: $blue;
            padding: 10px 15px 10px 15px;
            border: none;
            font-family: $arial;
            font-size: 15px;
            color: white;
            text-decoration: none;
            text-align: center;
            margin-bottom: 15px;
            left: 50%;
            position: relative;
            transform: translateX(-50%);
        }
    }
}

.main_content.grey {
    background-color: #F5F5F5;
}

.home_tra.retir.second {

    .retir_left {
      .tra_container {
        ul {
          list-style: none !important;
          li {
            padding: 0px !important;
          }
        }
      }
    }

    @media screen and (max-width: 955px) {
        // padding: 0 0 0 20px;
        padding: 0px;
    }
}
.home_tra.retir {
    background-color: white;

    @media screen and (max-width: 955px) {
        padding: 20px;
    }
    .retirement {
        // background-image: url('../img/retirement-image.jpg');
        background-position: right center;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        height: 596px;
        width: 50%;

        @media screen and (max-width: 955px) {
            width: 100%;
            height: auto;
            padding-top: 62%;
        }

        &.-logo {

          @media screen and (max-width: 955px) {
            padding: 30px 0;
          }

          img {
            display: block;
            width: calc(100% - 40px);
            height: auto;
            max-width: 560px;
            position: relative;
            @include valign;
            @include center;

            @media screen and (max-width: 955px) {
              position: static;
              transform: translateY(0);
              max-width: 450px;
            }

          }
        }

    }

    .retir {
        @media screen and (max-width: 955px) {
            width: 100%;
            // padding: 20px;
        }
        .tra_container {
            margin-left: 12%;
            margin-top: 55px;
            width: 70%;
            padding: 0px;

            @media screen and (max-width: 955px) {
                width: 100%;
                margin: 0;
                // padding: 0 20px;
            }
            h2 {
                font-family: $light;
                color: $dark-blue;
                font-size: 53px;
                font-weight: normal;
                border-bottom: 1px solid $red;
                padding-bottom: 15px;
                margin-bottom: 35px;

                @media screen and (max-width: 450px) {
                  font-size: 36px;
                }
            }
            p, a {
                font-family: $arial;
                font-size: 18px;
                color: $dark-blue;
            }
            a {
              float: none;
              margin: 0;
              text-decoration: underline;
              display: inline;
              font-weight: $bold;
            }
            ul,
            ol {
              padding-left: 15px;

              li {
                padding: 8px 0;
                color: $dark-blue;
                font-size: 18px;
              }
            }

            ul {
                list-style: disc;
            }

            ol {
                padding-left: 20px;
            }
        }
    }
    .retir_left {
        .tra_container {
            margin-left: 35%;
            padding-right: 140px;
            width: 55%;

            @media screen and (max-width: 955px) {
                width: 100%;
                margin: 0;
                padding: 0;
            }
            ul {
                margin-bottom: 30px;
                li {
                    a {
                        color: $dark-blue;
                        font-family: $arial;
                        font-size: 15px;
                        text-decoration: none;;
                        display: block;
                        float: none;
                        font-weight: bold;
                        margin-top: 15px;
                    }
                    a::before {
                        content: '';
                        left: 0;
                        background-image: url('../img/svg/icon--link-arrow-red.svg');
                        background-size: cover;
                        width: 6px;
                        height: 10px;
                        margin-right: 7px;
                        display: inline-block;
                    }
                }
            }
            a {
                display: block;
                float: right;
                color: $dark-blue;
                text-decoration: none;
                font-family: $medium;
                font-size: 17px;
                margin-bottom: 20px;
                span {
                    display: block;
                    background-image: url('../img/svg/icon--arrow-right-red.svg');
                    background-size: 26px 26px;
                    width: 26px;
                    height: 26px;
                    float: right;
                    margin-left: 10px;
                    margin-top: -3px;
                }
                @media screen and (max-width: 955px) {
                    margin-right: 20px;
                }
            }
        }
    }
    .white {
        background-color: white;
    }
    .blue {
        background-color: $dark-blue;
    }

    .tra.priv {
        @media screen and (max-width: 955px) {
            width: 100%;
            padding: 20px;
        }
        .quote {
            margin-bottom: 65px;

            @media screen and (max-width: 955px) {
                margin: 0;
            }
            @media screen and (max-width: 920px) {
              padding: 0px !important;
            }
            h3 {
                width: 510px;

                @media screen and (max-width: 1170px) {
                    width: 95%;
                }
                @media screen and (max-width:955px) {
                    width: 100%;
                    margin-top: 0px;
                }
            }
        }
    }
}

/* .mobile_image {
    .retirement {
        display: none;

        @media screen and (max-width: 955px) {
            background-image: url('../img/retirement-image.jpg');
            display: block;
            background-repeat: no-repeat;
            background-size: cover;
            height: 156px;
        }
    }
} */

.complimentary-services {
    @include clearfix;

    width: 100%;

    @media screen and (min-width: 955px) {
        background: linear-gradient(90deg, #ffffff 50%, $dark-blue 50%);
    }

    .container-fix-right {
        background-color: $dark-blue;
    }

    .cs-left {
        @include clearfix;

        width: 50%;
        float: left;
        box-sizing: border-box;
        padding: 50px 70px 50px 0px;

        @media screen and (max-width: 955px) {
            width: 100%;
            float: none;
            padding: 50px 0;
        }

        h2 {
            font-family: $light;
            color: $dark-blue;
            font-size: 53px;
            font-weight: normal;
            border-bottom: 1px solid $red;
            padding-bottom: 15px;
            margin-bottom: 35px;

            @media screen and (max-width: 450px) {
              font-size: 36px;
            }
        }

        ul {
            margin-bottom: 30px;

            li {
                margin-left: 20px;

                a {
                    color: $dark-blue;
                    font-family: $arial;
                    font-size: 15px;
                    text-decoration: none;;
                    display: block;
                    float: none;
                    font-weight: bold;
                    margin-top: 15px;

                    &::before {
                        content: '';
                        left: 0;
                        background-image: url('../img/svg/icon--link-arrow-red.svg');
                        background-size: cover;
                        width: 6px;
                        height: 10px;
                        margin-right: 7px;
                        display: inline-block;
                    }
                }
            }
        }

        a.cta {
            display: block;
            text-align: right;
            color: $dark-blue;
            text-decoration: none;
            font-family: $medium;
            font-size: 17px;
            margin-bottom: 20px;

            background-image: url('../img/svg/icon--arrow-right-red.svg');
            background-size: 26px 26px;
            background-position: center right;
            background-repeat: no-repeat;
            padding: {
                top: 5px;
                right: 30px;
                bottom: 5px;
            }

            @media screen and (max-width: 955px) {
                margin-right: 20px;
            }
        }
    }

    .cs-right {
        width: 50%;
        float: right;
        box-sizing: border-box;
        background-color: $dark-blue;
        padding: 50px 0px 50px 70px;

        @media screen and (max-width: 955px) {
            width: 100%;
            float: none;
            padding: 50px 0;
        }

        p {
            font-family: $light;
            font-size: 32px;
            color: white;
            font-weight: normal;

            @media screen and (max-width: 920px) {
                margin-top: 0px;
            }

            &:before {
                content: '"';
                color: $red;
                font-weight: bold;
                font-size: 35px;
                line-height: 0;
            }

            &:after {
                content: '"';
                color: $red;
                font-weight: bold;
                font-size: 35px;
                line-height: 0;
            }
        }

        h5 {
            font-family: $arial;
            font-size: 18px;
            color: #fff;
            font-weight: normal;
        }
    }
}
