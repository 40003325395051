.aspira--banner {
  background-color: $blue;
  padding: 40px 0;

  h1 {
    font-family: $light;
    font-size: 52px;
    color: white;
    text-align: center;
    margin: 0;
  }

}

.aspira--intro {
  padding: 30px 0;

  .aspira--intro-flex {
    display: flex;
    align-items: center;

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
    }

    .aspira--intro-logo {
      width: auto;
      min-width: 220px;
      margin-right: 40px;

      @media screen and (max-width: 650px) {
        max-width: 100%;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: auto;

        @media screen and (max-width: 650px) {
          max-width: 220px;
          height: auto;
        }

      }

    }

    .aspira--intro-copy {

      p {
        font-family: $book;
        font-size: 25px;
        color: $dark-blue;
        text-align: left;
      }

    }

  }

  .aspira--book {
    display: block;
    background-color: $red;
    width: 100%;
    max-width: 400px;
    padding: 18px 5px;
    text-align: center;
    margin: 0 auto;
    font-family: $arial;
    font-size: 16px;
    color: white;
    text-decoration: none;
    margin-top: 40px;
    @include animate;
    background-image: url('../img/svg/icon--right-arrow-white.svg');
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: center right 55px;

    @media screen and (max-width: 450px) {
      background-image: none;
    }

    &:hover {
      background-color: $dark-blue
    }

  }

}

.aspira--videos {
  padding: 50px 0;

  h2 {
    text-align: center;
    font-family: $book;
    font-size: 34px;
    color: $dark-blue;
    margin: 0 0 30px 0;
  }

  .aspira--videos-flex {
    display: flex;
    flex-wrap: wrap;

    .aspira--video {
      display: block;
      width: calc(100% / 3 - 14px);
      background-color: $dark-blue;
      text-decoration: none;
      color: white;
      @include animate;
      margin: 0 20px 20px 0;

      &:nth-child(3n) {
        margin-right: 0;

        @media screen and (max-width: 750px) {
          margin-right: 20px;
        }

      }

      &:focus {
        outline: none;
      }

      @media screen and (max-width: 750px) {
        width: calc(100% / 2 - 14px);

        &:nth-child(even) {
          margin-right: 0;
        }

      }

      @media screen and (max-width: 500px) {
        width: 100%;
        margin: 0 0 20px 0 !important;
      }

      &:nth-child(2) {
        // margin: 0 10px;

        @media screen and (max-width: 750px) {
          margin-right: 0;
          margin-left: 0;
        }

      }

      &:hover {
        background-color: $red;
      }

      .aspira--video-poster {
        position: relative;

        span {
          display: block;
          background-image: url('../img/svg/icon--yt-play.svg');
          background-size: 62px 44px;
          background-repeat: no-repeat;
          background-position: center center;
          width: 62px;
          height: 44px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        img {
          width: 100%;
          height: auto;
        }

      }

      .aspira--video-copy {
        padding: 30px 20px;
        position: relative;
        height: 5rem;

        p {
          font-family: $book;
          font-size: 21px;
          color: white;
          text-decoration: none;
          text-align: center;
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          @include center;
          padding: 0 20px;
        }

      }

    }

  }

  .playlist--button {
    display: block;
    background-color: $blue;
    text-align: center;
    margin: 0 auto;
    width: calc(100% - 20px);
    margin-top: 40px;
    padding: 20px 10px;
    font-family: $arial;
    font-size: 16px;
    color: white;
    text-decoration: none;
    @include animate;
    position: relative;

    &:hover {
      background-color: $dark-blue;
    }

    span {
      display: inline-block;
      background-image: url('../img/svg/icon--right-arrow-white.svg');
      background-size: 26px 26px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 26px;
      height: 26px;
      position: absolute;
      margin-left: 10px;
      top: 15px;

      @media screen and (max-width: 400px) {
        display: none;
      }

    }

  }

}
.aspira--pods {
  padding: 0 0 40px 0;

  .aspira--pods-flex {
    display: flex;
    flex-wrap: wrap;

    .aspira--pod {
      width: calc(100% / 3 - 7px);
      text-decoration: none;
      color: $dark-blue;
      margin: 0 10px 10px 0;

      @media screen and (max-width: 800px) {
        width: calc(100% / 2 - 7px);
        margin-bottom: 10px !important;
        margin-right: 0;

        &:nth-child(2n+2) {
          margin-left: 10px !important;
        }

      }

      @media screen and (max-width: 550px) {
        width: 100%;
        margin: 0 0 10px 0 !important;
      }

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      .aspira--pod-icon {
        background-color: $dark-blue;
        padding: 30px 10px;

        img {
          display: block;
          margin: 0 auto;
        }

      }

      .aspira--pod-copy {
        height: 130px;
        font-family: $book;
        font-size: 21px;
        color: $dark-blue;
        text-align: center;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          font-family: $arial;
          font-weight: $bold;
          color: $dark-blue;
          @include animate;
          display: block;
          margin-top: auto;

          &:after {
            display: inline-block;
            content: '';
            background-image: url('../img/svg/icon--arrow-right-red.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 26px 26px;
            width: 26px;
            height: 26px;
            position: relative;
            top: 7px;
            margin-left: 12px;
            margin-top: 20px;
          }

        }

      }

      &:hover {
        .aspira--pod-copy {
          span {
            color: $red;
          }
        }
      }

    }

  }

}
