.complaints-procedure {

  .banner.private {
    height: 530px;

    .content {
      h1 {
        padding-bottom: 33px !important;
        margin-bottom: 0px !important;
      }
    }
  }

  .main_content {
    .text {
      width: 50%;

      @media screen and (max-width: 660px) {
        width: 100%;
        margin-top: 40px;
      }

      a {
        color: $dark-blue;
      }

      ul {
        li {
          &:last-child {
            margin-bottom: 12px;
          }
        }
      }

    }

    .cta {
      margin-top: 0px !important;
      max-width: 280px;

      @media screen and (max-width: 660px) {
        float: none;
        margin: 0 auto;
      }

      a {
        span {
          margin-top: -2px;
        }
      }

      .padded {
        font-family: $light;
        font-size: 21px;
        color: white;
        font-weight: $lighte;
        margin-bottom: 20px;
      }

    }

  }

}
