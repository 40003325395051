.search_page {

  h1 {
    font-family: $light;
    font-weight: normal;
    color: $red;
    font-size: 55px;
    margin-bottom: 30px;
  }

  p {
    font-family: $arial;
    color:$dark-blue;
    font-size: 16px;

    .keyword {
        font-weight: bold;
        color: $red;
    }

  }

  h2 a {
    font-family: $medium;
    font-size: 25px;
    color: $dark-blue;
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 40px;
  }

  a {

    &.next {
      font-weight: bold;
      text-decoration: none;
      color: $dark-blue;
      
    }

  }

}
