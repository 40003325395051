.mylebc--banner {
  &.protection {

  }
}

.mylebc--important {
  background-color: #eeeeee;
  padding: 60px 0;
  position: relative;
  min-height: 300px;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
    min-height: auto;
  }

  .image {
    position: absolute;
    bottom: 0;
    left: 12%;

    @media screen and (max-width: 767px) {
      display: none;
    }

    img {
      display: block;
    }

  }

  .mylebc--important-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    z-index: 1000;

    .heading {
      width: 25%;
      margin-right: 15%;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }

      h2 {
        font-family: $medium;
        font-size: 27px;
        color: $dark-blue;
        margin: 0 0 20px 0;
        line-height: 35px;

        span {
          color: $blue;
        }

      }

    }

    .copy {
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      p {
        font-family: $arial;
        font-size: 14px;
        color: $dark-blue;
        margin: 0;
        padding-bottom: 20px;

        strong {
          font-weight: bold;
        }

        a {
          color: $dark-blue;
          font-weight: bold;
          text-decoration: underline;
        }

        &:last-child {
          padding-bottom: 0;
        }

      }

      .link {
        display: block;
        margin-top: 20px;
        background-color: $dark-blue;
        font-family: $medium;
        font-size: 14px;
        color: white;
        text-decoration: none;
        padding: 15px 35px 15px 20px;
        width: calc(100% - 55px);
        max-width: 100px;
        background-image: url('../img/svg/icon--link-arrow-red.svg');
        background-repeat: no-repeat;
        background-position: center right 15px;
        background-size: 9px 16px;
        @include animate;

        &:hover {
          color: $red;
        }

      }

    }

  }

}

.mylebc--effective {
  padding: 60px 0;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  .intro {
    margin-bottom: 50px;

    h2 {
      font-family: $book;
      font-size: 27px;
      color: $dark-blue;
      margin: 0 0 20px 0;

      strong {
        font-family: $medium;
      }

    }

  }

  .mylebc--effective-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .col {
      display: inline-block;
      width: calc(100% / 3 - 40px);

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &:nth-child(2) {
        margin: 0 60px;

        @media screen and (max-width: 767px) {
          margin: 40px 0;
        }

      }

      .icon {
        width: 100%;
        max-width: 90px;
        margin-bottom: 20px;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

      }

      .copy {

        h3 {
          font-family: $book;
          font-size: 24px;
          color: $dark-blue;
          margin: 0 0 15px 0;
        }

        p {
          font-family: $arial;
          font-size: 14px;
          color: $dark-blue;
          padding-bottom: 20px;
          margin: 0;

          a {
            color: $dark-blue;
            text-decoration: underline;
          }

          &:last-child { padding-bottom: 0 }

        }

      }

      .link {
        display: block;
        margin-top: 20px;
        background-color: $dark-blue;
        font-family: $medium;
        font-size: 14px;
        color: white;
        text-decoration: none;
        padding: 15px 35px 15px 20px;
        width: calc(100% - 55px);
        max-width: 100px;
        background-image: url('../img/svg/icon--link-arrow-red.svg');
        background-repeat: no-repeat;
        background-position: center right 15px;
        background-size: 9px 15px;
        @include animate;

        &:hover {
          color: $red;
        }

      }

    }

  }

}
